import React from 'react';
import { Form, Row, Col, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaCopy, FaWallet,FaInfo} from "react-icons/fa";
import info from "../Assets/Images/Group 314.svg";

import PulseLoader from 'react-spinners/PulseLoader';
/**
 * Component rendering a form with input fields and a submit button.
 *
 * @param {Object} props - Component props containing form details.
 * @param {string} props.label - Label for the form input field.
 * @param {string} props.controlId - Control ID for form accessibility.
 * @param {string} props.value - Value of the input field.
 * @param {Function} props.onChange - Function to handle input change.
 * @param {string} props.placeholder - Placeholder text for the input field.
 * @param {Function} props.onSubmit - Function to handle form submission.
 * @param {boolean} props.buttonLoading - Flag indicating if the submit button is in loading state.
 * @param {boolean} props.isValid - Flag indicating if the input is valid.
 * @returns {JSX.Element} FormComponent - Component rendering a form with input and submit button.
 */

const FormComponent = ({ label, controlId, value, onChange, placeholder, onSubmit, buttonLoading,isValid,showSubmit,validationMessage,infoText,setFee }) => {
  const handleFormSubmit = (e) => {
    if (!showSubmit) {
      e.preventDefault();
      return;
    }
    if (onSubmit) {
      onSubmit(e);
    }
  };
  return (
<>
  <Form onSubmit={handleFormSubmit}>
    <Form.Group as={Row} className="mb-3" controlId={controlId}>
    <div className="EtcBalance">
    <Form.Label>
          {label}{' '}
          {infoText && (

            <div className="info">
            <div className="inner-info">
            <img src={info} alt="pic" />
                  <div className="title-info">{infoText} </div>
            </div>
          </div>
            )}
        </Form.Label>     
        </div>
         <Col sm="8">
                 <Form.Control
          type="text"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          isInvalid={!isValid}

        />
        <Form.Control.Feedback type="invalid">
            {validationMessage}
          </Form.Control.Feedback>
                    {/* {!isValid && <p style={{color: 'red'}}>Invalid address</p>} */}
                    {/* {!isValid && validationMessage && (
            <p style={{ color: 'red' }}>{validationMessage}</p>
          )} */}
         
      </Col>
     
      {showSubmit && (
        <Col sm="2" className="d-flex justify-content-end align-items-center ms-4">
          <Button
            disabled={buttonLoading ||!isValid}
            type="submit"
            className="reg-btn1 dark w-100"
          >
            Submit
            {/* {buttonLoading ? <PulseLoader size={10} color="#fff" /> : 'Submit'} */}
          </Button>
        </Col>
      )}
    </Form.Group>

  </Form>
  <Col sm="3">
  {setFee && (
            <p className='feeDetail'>{setFee}</p>
          )}
          </Col>
  </>
  
);
      };
export default FormComponent;


import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import FormComponent from './FormComponent';
/**
 * Component that renders a set of tabs based on the provided data.
 * Each tab contains forms rendered using FormComponent.
 *
 * @param {Object[]} tabs - Array of tab objects containing eventKey, title, and forms data.
 * @returns {JSX.Element} TabsComponent - Component rendering Bootstrap Tabs with nested forms.
 */
const TabsComponent = ({ tabs }) => {
  return (
    <Tabs defaultActiveKey={tabs[0].eventKey} id="admin-tabs" className="tabitem dark mb-3">
      {tabs.map(({ eventKey, title, forms }) => (
        <Tab eventKey={eventKey} title={title} key={eventKey}>
          <div className="welcome-pnl">
            <div className="welcome-inner">
              {forms.map((form, index) => (
                // <FormComponent key={index} {...form} />
                <FormComponent key={index} {...form} showSubmit={form.showSubmit}infoText={form.infoText}setFee={form.setFee} />

              ))}
            </div>
          </div>
        </Tab>
      ))}
    </Tabs>
  );
};

export default TabsComponent;


import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import { ethers } from "ethers"
import { contract_api } from "./actions_abi";
import Toast from "../Toast/toast";
import { token_abi } from "./token_abi"
import { vault_abi } from "./vault_abi"
import { liquidation_abi } from "./liquidation";
import { TransactionHistoryAbi } from "./TransactionHistory_abi"
import { oracle_Abi } from "./oracle_abi"
import { apy_api } from "./apy_abi";
import addresses, { Base_Url, GAS_LIMIT, SOME_VALUE, convertEtherToWei } from "../../Const/const";
import axios from "axios";
import { ApyMapper } from "./ApyMapper";
import { EthJoinAbi } from "./ETHJoinabi";
import { ErusdJoin } from "./EursdJoinabi";
import { ERUSDAbi } from "./ERUSDabi";
import { oraclePriceAbi } from "./oraclePriceabi";
import { logger } from "../../Const/utils/loggers";
import { SwapAbi } from "./Swap_abi";
import { ApyContractAbi } from "./ApyContatactAbi";



export const Current_owner = async (tokenAddress) => {
  const Web3 = require('web3');
  // const web3 = new Web3(new Web3.providers.HttpProvider('https://dataseed2.redlightscan.finance/'));
  const web3 = new Web3(new Web3.providers.HttpProvider('https://dataseed2.redlightscan.finance/'));
  return new Promise((resolve, reject) => {

    const contract = new web3.eth.Contract(contract_api, tokenAddress);

    contract.methods.owner().call().then(resp => {
      resolve(resp)

    }).catch(error => {
      reject(error)
    })

  })

}
export const getETHCalculatedAmount = async (tokenaddress, erusd, collateral) => {
  const provider = await detectEthereumProvider();
  const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);

  const signer = web3Provider.getSigner();

  const contract = new ethers.Contract(tokenaddress, contract_api, signer);

  try {
    const result = await contract.getETHCalculatedAmount(erusd, collateral);
    await result;
    return result;
  } catch (error) {
    throw error;
  }
};




export const stabilityFee = async (tokenaddress) => {
  const provider = await detectEthereumProvider();
  const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);

  const signer = web3Provider.getSigner();

  const contract = new ethers.Contract(tokenaddress, contract_api, signer);

  try {
    const result = await contract.stabilityFee();
    await result;
    return result;
  } catch (error) {
    throw error;
  }
};
export const getERUSDCalculatedAmount = async (tokenaddress, tokenAmount) => {
  const provider = await detectEthereumProvider();
  const web3Provider =
    provider !== window.ethereum
      ? new ethers.providers.Web3Provider(provider)
      : new ethers.providers.Web3Provider(window.ethereum);

  const signer = web3Provider.getSigner();

  const contract = new ethers.Contract(tokenaddress, contract_api, signer);
  try {
    const result = await contract.getERUSDCalculatedAmount(tokenAmount);
    return result;
  } catch (error) {
  }
};

export const minCollateralRatio = async (tokenaddress) => {
  const provider = await detectEthereumProvider();
  const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);

  const signer = web3Provider.getSigner();

  const contract = new ethers.Contract(tokenaddress, contract_api, signer);

  try {
    const result = await contract.minCollateralRatio();
    await result;
    return result;
  } catch (error) {
    throw error;
  }
};
export const APYFee = async (tokenaddress) => {
  const provider = await detectEthereumProvider();
  const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);

  const signer = web3Provider.getSigner();

  const contract = new ethers.Contract(tokenaddress, apy_api, signer);

  try {
    const result = await contract.APYPercentage();
    await result;
    return result;
  } catch (error) {
    throw error;
  }
};
export const LatestAPYFee = async (tokenaddress) => {
  const provider = await detectEthereumProvider();
  const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);

  const signer = web3Provider.getSigner();

  const contract = new ethers.Contract(addresses.ApyMapper, ApyMapper, signer);
  try {
    const result = await contract.getLatestAPYContractDetail();

    await result;
    return result;
  } catch (error) {
    throw error;
  }
};
export const getbalanceOf = async (ERUSDToken, address) => {

  try {
    const provider = await detectEthereumProvider();
    if (!provider) {
      throw new Error('Ethereum provider not found.');
    }
    const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(ERUSDToken, token_abi, signer);
    const result = await contract.balanceOf(addresses.ETHJoin);

    return result;
  } catch (error) {
    throw error;
  }
};
export const getbalanceOfSwap = async (ERUSDToken, address) => {

  try {
    const provider = await detectEthereumProvider();
    if (!provider) {
      throw new Error('Ethereum provider not found.');
    }
    const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(ERUSDToken, token_abi, signer);
    const result = await contract.balanceOf(addresses.Swap);

    return result;
  } catch (error) {
    throw error;
  }
};
export const getbalanceOfERUSD = async (ERUSDToken, address) => {

  try {
    const provider = await detectEthereumProvider();
    if (!provider) {
      throw new Error('Ethereum provider not found.');
    }
    const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(ERUSDToken, vault_abi, signer);
    const result = await contract.ERUSD(address);

    return result;
  } catch (error) {
    throw error;
  }
};

export const totalSupply = async (ERUSDToken) => {

  try {
    const provider = await detectEthereumProvider();
    if (!provider) {
      throw new Error('Ethereum provider not found.');
    }
    const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(ERUSDToken, token_abi, signer);
    const result = await contract.totalSupply();
    return result;
  } catch (error) {

  }
}
export const userCount = async (ERUSDToken) => {

  try {
    const provider = await detectEthereumProvider();
    if (!provider) {
      throw new Error('Ethereum provider not found.');
    }
    const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(ERUSDToken, vault_abi, signer);
    const result = await contract.userCount();
    return result;
  } catch (error) {

  }
}

export const getBalance = async (account) => {
  try {
    // Check if the user has MetaMask installed
    if (window.ethereum && account) {
      const web3 = new Web3(window.ethereum);

      // Request account access if needed
      await window.ethereum.enable();

      // Get the balance using the current provider
      const balanceWei = await web3.eth.getBalance(account);

      // Convert Wei to Ether and return the result
      const balanceEth = web3.utils.fromWei(balanceWei, 'ether');
      return balanceEth;
    } else {
      throw new Error('MetaMask not detected. Please install MetaMask and try again.');
    }
  } catch (error) {
    return '0';
  }
};
export const balanceTotalCollateral = async (account) => {
  try {
    // Check if the user has MetaMask installed
    if (window.ethereum && account) {
      const web3 = new Web3(window.ethereum);

      // Request account access if needed
      await window.ethereum.enable();

      // Get the balance using the current provider
      const balanceWei = await web3.eth.getBalance(account);

      // Convert Wei to Ether and return the result
      const balanceEth = web3.utils.fromWei(balanceWei, 'ether');
      return balanceEth;
    } else {
      throw new Error('MetaMask not detected. Please install MetaMask and try again.');
    }
  } catch (error) {
    return '0';
  }
};

export const switchingToRLC = async () => {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x3F' }],
    });

  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x3F',
              chainName: 'ETH Classic',
              rpcUrls: ['https://geth-mordor.etc-network.info/'],
              blockExplorerUrls: ['https://blockscout.com/etc/mordor'],
              nativeCurrency: {
                symbol: 'ETC',
                decimals: 18
              }
            }
          ],
        })
      } catch (addError) {
      }
    }
  }

}


export const lockAndDraw = async (constraceAddress, erusd, collatrael, AmountPayable, tokenaddress) => {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
    window.web3 = new Web3(provider);
  } else {
    window.web3 = new Web3(window.ethereum);
  }

  return new Promise(async (resolve, reject) => {
    axios.post(`${Base_Url}UpdateContract`)
    .then(response => {
      if (response?.data?.Message === "successfully updated") {
  setTimeout(() => {
        const provider1 = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider1.getSigner();
        const web3 = new Web3(window.ethereum);
        const amountInWei = web3.utils.toWei(AmountPayable, 'ether');

        const contract = new ethers.Contract(constraceAddress, contract_api, signer);

        const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);
        const contract2 = new ethers.Contract(constraceAddress, contract_api, signer);

        contract2.getETHCalculatedAmount(erusd, collatrael)
        .then(async (result) => {
          const decimalResult = parseInt(result?._hex, 16);
          const EthResult = decimalResult / 10 ** 18;
          const ErusdFee = EthResult * 0.04;
          const SumofErusd = EthResult + ErusdFee;
          const finalresult= SumofErusd+SOME_VALUE
          let amountInWei2 = parseInt(finalresult *1e18)
          web3.utils.toWei(finalresult.toString(), 'ether');
        //  
                    const weiValue1 = convertEtherToWei(finalresult); 

          contract.lockAndDraw(erusd, collatrael, {
            value: amountInWei2.toString(),
            // gasLimit: GAS_LIMIT,
          })
          .then(async (res) => {
            await res.wait();
            resolve(res); 
          })
          .catch((error) => {
            reject(error);
          });
        })
        .catch((error) => {
          reject(error);
        });
}, 15000)
      } else {
        reject(new Error("API response indicates failure"));
      }
    })
    .catch(error => {
      reject(error);
    });
  });
};


export const withdrawCollateral = async (contractAddress, sellErusd) => {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
      window.web3 = new Web3(provider);
  } else {
      window.web3 = new Web3(window.ethereum);
  }

  return new Promise(async (resolve, reject) => {
      axios.post(`${Base_Url}UpdateContract`)
          .then(response => {
              if (response?.data?.Message === "successfully updated") {
                  const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                  const signer = provider1.getSigner();
                  const contract = new ethers.Contract(contractAddress, contract_api, signer);
                   contract.withdrawCollateral(
                     { 
                      gasLimit: GAS_LIMIT,
                   }
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              } else {
                  reject(new Error("API response indicates failure"));
              }
          })
          .catch((error) => {
              reject(error);

          });
  });
};




export const erusdAssest = async (VaultToken, address) => {

  try {
    const provider = await detectEthereumProvider();
    if (!provider) {
      throw new Error('Ethereum provider not found.');
    }
    const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(VaultToken, vault_abi, signer);
    const result = await contract.ERUSD(address);
    return result;
  } catch (error) {

  }
}
export const ethAssest = async (VaultToken, address) => {

  try {
    const provider = await detectEthereumProvider();
    if (!provider) {
      throw new Error('Ethereum provider not found.');
    }
    const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(VaultToken, vault_abi, signer);
    const result = await contract.eth(address);
    return result;
  } catch (error) {

  }
}

export const liquidation_amount = async (liquidation, address) => {

  try {
    const provider = await detectEthereumProvider();
    if (!provider) {
      throw new Error('Ethereum provider not found.');
    }
    const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();

    const contract = new ethers.Contract(liquidation, liquidation_abi, signer);
                                  
    const result = await contract.getLiquidationPercentage(address);
    return result;
  } catch (error) {

  }
}
export const liquidation_amountLimit = async (liquidation, address) => {

  try {
    const provider = await detectEthereumProvider();
    if (!provider) {
      throw new Error('Ethereum provider not found.');
    }
    const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();

    const contract = new ethers.Contract(liquidation, liquidation_abi, signer);
                                  
    const result = await contract.getLiquidityLimit();
    return result;
  } catch (error) {

  }
}

export const Usertransactionhistory = async (transactionHistory, address, page) => {
  try {
    const provider = await detectEthereumProvider();
    if (!provider) {
      throw new Error('Ethereum provider not found.');
    }
    const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(transactionHistory, TransactionHistoryAbi, signer);
    const result = await contract.getUserTransactions(address, page, 5);
    return result;
  } catch (error) {

  }
}

export const transactionhistorylenght = async (transactionHistory, address) => {
  try {
    const provider = await detectEthereumProvider();
    if (!provider) {
      throw new Error('Ethereum provider not found.');
    }
    const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(transactionHistory, TransactionHistoryAbi, signer);
    const result = await contract.getUserTransactionLength(address);
    return result;
  } catch (error) {

  }
}

export const getAmountpercentage = async (oraclePrice, ethInWei) => {
  try {
    const provider = await detectEthereumProvider();
    if (!provider) {
      throw new Error('Ethereum provider not found.');
    }
    const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(oraclePrice, oracle_Abi, signer);
    const result = await contract.getAmount(ethInWei);
    return result;
  } catch (error) {

  }
}
export const DEFAULT_COLLATERAL = async (vault, address) => {
  try {
    const provider = await detectEthereumProvider();
    if (!provider) {
      throw new Error('Ethereum provider not found.');
    }
    const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(vault, vault_abi, signer);
    const result = await contract.userUSDTAmount(address);
    return result;
  } catch (error) {

  }
}


export const APY_CALCULATE = async (apy, address) => {
  try {
    const provider = await detectEthereumProvider();
    if (!provider) {
      throw new Error('Ethereum provider not found.');
    }
    const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(apy, apy_api, signer);
    const result = await contract.calculate(address);
    return result;
  } catch (error) {

  }
}
export const UserAPY_CALCULATE = async (apy, address) => {
  try {
    const provider = await detectEthereumProvider();
    if (!provider) {
      throw new Error('Ethereum provider not found.');
    }
    const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(apy, contract_api, signer);
    const result = await contract.getUserTotalAPY(address);
    return result;
  } catch (error) {

  }
}
export const WITHDRAW_APY_AMOUNT = async (action) => {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
    window.web3 = new Web3(provider);
  } else {
    window.web3 = new Web3(window.ethereum);
  }

  return new Promise(async (resolve, reject) => {
    try {
      const provider1 = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider1.getSigner();
      let contract = new ethers.Contract(action, contract_api, signer);
      
      const res = await contract.withdrawAPYAmount();
      const receipt = await res.wait();
      
      resolve(receipt);
    } catch (error) {
      reject(error);
    }
  });
};

export const ETC_AMOUNT = async (oracle, eth) => {
  try {
    const provider = await detectEthereumProvider();
    if (!provider) {
      throw new Error('Ethereum provider not found.');
    }
    const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();
    const contract = new ethers.Contract(oracle, oracle_Abi, signer);
    const result = await contract.getAmount(eth);
    return result;
  } catch (error) {

  }
}




export const getRenewOwner = async () => {
  if (typeof window.ethereum !== 'undefined') {
      try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
      } catch (error) {
          logger.error('User denied account access');
          return null;
      }
      
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(contract_api, addresses.contractAddress);
      return new Promise((resolve, reject) => {
          contract.methods.owner().call()
              .then(resp => resolve(resp))
              .catch(error => reject(error));
      });
  } else {
      logger.error('MetaMask is not installed.');
      return null;
  }
};
/**
 * Retrieves the owner of the Transaction History contract.
 *
 * This function checks if the Ethereum provider (MetaMask) is available and requests account access.
 * If access is granted, it creates a Web3 instance and connects to the Transaction History contract using the specified ABI and address.
 * It then retrieves and returns the owner of the contract.
 *
 * @async
 * @function getTransactionHistoryOwner
 * @returns {Promise<string|null>} The address of the contract owner if successful, or null if an error occurs.
 * @throws Will throw an error if unable to retrieve the contract owner.
 */
export const getTransactionHistoryOwner = async () => {
  if (typeof window.ethereum !== 'undefined') {
      try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
      } catch (error) {
          logger.error('User denied account access');
          return null;
      }
      
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(TransactionHistoryAbi, addresses.TransactionHistory);
      return new Promise((resolve, reject) => {
          contract.methods.owner().call()
              .then(resp => resolve(resp))
              .catch(error => reject(error));
      });
  } else {
      logger.error('MetaMask is not installed.');
      return null;
  }
};
/**
 * Retrieves the owner of the Liquidation contract.
 *
 * This function checks if the Ethereum provider (MetaMask) is available and requests account access.
 * If access is granted, it creates a Web3 instance and connects to the Liquidation contract using the specified ABI and address.
 * It then retrieves and returns the owner of the contract.
 *
 * @async
 * @function getLiquidationOwner
 * @returns {Promise<string|null>} The address of the contract owner if successful, or null if an error occurs.
 * @throws Will throw an error if unable to retrieve the contract owner.
 */
export const getLiquidationOwner = async () => {
  if (typeof window.ethereum !== 'undefined') {
      try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
      } catch (error) {
          logger.error('User denied account access');
          return null;
      }
      
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(liquidation_abi, addresses.Liquidation);
      return new Promise((resolve, reject) => {
          contract.methods.owner().call()
              .then(resp => resolve(resp))
              .catch(error => reject(error));
      });
  } else {
      logger.error('MetaMask is not installed.');
      return null;
  }
};
/**
 * Retrieves the owner of the vaultOwner contract.
 *
 * This function checks if the Ethereum provider (MetaMask) is available and requests account access.
 * If access is granted, it creates a Web3 instance and connects to the vaultOwner contract using the specified ABI and address.
 * It then retrieves and returns the owner of the contract.
 *
 * @async
 * @function getvaultOwner
 * @returns {Promise<string|null>} The address of the contract owner if successful, or null if an error occurs.
 * @throws Will throw an error if unable to retrieve the contract owner.
 */
export const getvaultOwner = async () => {
  if (typeof window.ethereum !== 'undefined') {
      try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
      } catch (error) {
          logger.error('User denied account access');
          return null;
      }
      
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(vault_abi, addresses.vault);
      return new Promise((resolve, reject) => {
          contract.methods.owner().call()
              .then(resp => resolve(resp))
              .catch(error => reject(error));
      });
  } else {
      logger.error('MetaMask is not installed.');
      return null;
  }
};
/**
 * Retrieves the owner of the ETH Join contract.
 *
 * This function checks if the Ethereum provider (MetaMask) is available and requests account access.
 * If access is granted, it creates a Web3 instance and connects to the ETH Join contract using the specified ABI and address.
 * It then retrieves and returns the owner of the contract.
 *
 * @async
 * @function getETHJoinOwner
 * @returns {Promise<string|null>} The address of the contract owner if successful, or null if an error occurs.
 * @throws Will throw an error if unable to retrieve the contract owner.
 */
export const getETHJoinOwner = async () => {
  if (typeof window.ethereum !== 'undefined') {
      try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
      } catch (error) {
          logger.error('User denied account access');
          return null;
      }
      
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(EthJoinAbi, addresses.ETHJoin);
      return new Promise((resolve, reject) => {
          contract.methods.owner().call()
              .then(resp => resolve(resp))
              .catch(error => reject(error));
      });
  } else {
      logger.error('MetaMask is not installed.');
      return null;
  }
};
/**
 * Retrieves the owner of the ERUSD Join contract.
 *
 * This function checks if the Ethereum provider (MetaMask) is available and requests account access.
 * If access is granted, it creates a Web3 instance and connects to the ERUSD Join contract using the specified ABI and address.
 * It then retrieves and returns the owner of the contract.
 *
 * @async
 * @function getErusdOwner
 * @returns {Promise<string|null>} The address of the contract owner if successful, or null if an error occurs.
 * @throws Will throw an error if unable to retrieve the contract owner.
 */
export const getErusdOwner = async () => {
  if (typeof window.ethereum !== 'undefined') {
      try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
      } catch (error) {
          logger.error('User denied account access');
          return null;
      }
      
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(ErusdJoin, addresses.ERUSDJoin);
      return new Promise((resolve, reject) => {
          contract.methods.owner().call()
              .then(resp => resolve(resp))
              .catch(error => reject(error));
      });
  } else {
      logger.error('MetaMask is not installed.');
      return null;
  }
};
/**
 * Retrieves the owner of the ERUSD Token contract.
 *
 * This function checks if the Ethereum provider (MetaMask) is available and requests account access.
 * If access is granted, it creates a Web3 instance and connects to the ERUSD Token contract using the specified ABI and address.
 * It then retrieves and returns the owner of the contract.
 *
 * @async
 * @function getERUSDTokenOwner
 * @returns {Promise<string|null>} The address of the contract owner if successful, or null if an error occurs.
 * @throws Will throw an error if unable to retrieve the contract owner.
 */
export const getERUSDTokenOwner = async () => {
  try {
      if (typeof window.ethereum !== 'undefined') {
          await window.ethereum.request({ method: 'eth_requestAccounts' });

          const web3 = new Web3(window.ethereum);
          const contract = new web3.eth.Contract(ERUSDAbi, addresses.ERUSDToken);
          
          return await contract.methods.owner().call();
      } else {
          logger.error('MetaMask is not installed or not connected.');
          return null;
      }
  } catch (error) {
      logger.error('Error fetching owner:', error.message);
      return null;
  }
};
/**
 * Retrieves the owner of the Oracle Price contract.
 *
 * This function checks if the Ethereum provider (MetaMask) is available and requests account access.
 * If access is granted, it creates a Web3 instance and connects to the Oracle Price contract using the specified ABI and address.
 * It then retrieves and returns the owner of the contract.
 *
 * @async
 * @function getoraclePriceOwner
 * @returns {Promise<string|null>} The address of the contract owner if successful, or null if an error occurs.
 * @throws Will throw an error if unable to retrieve the contract owner.
 */
export const getoraclePriceOwner = async () => {
  if (typeof window.ethereum !== 'undefined') {
      try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
      } catch (error) {
          logger.error('User denied account access');
          return null;
      }
      
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(oraclePriceAbi, addresses.oraclePrice);
      return new Promise((resolve, reject) => {
          contract.methods.owner().call()
              .then(resp => resolve(resp))
              .catch(error => reject(error));
      });
  } else {
      logger.error('MetaMask is not installed.');
      return null;
  }
};
/**
 * Retrieves the owner of the APY contract.
 *
 * This function checks if the Ethereum provider (MetaMask) is available and requests account access.
 * If access is granted, it creates a Web3 instance and connects to the APY contract using the specified ABI and address.
 * It then retrieves and returns the owner of the contract.
 *
 * @async
 * @function getaypcontractOwner
 * @returns {Promise<string|null>} The address of the contract owner if successful, or null if an error occurs.
 * @throws Will throw an error if unable to retrieve the contract owner.
 */
export const getaypcontractOwner = async () => {
  if (typeof window.ethereum !== 'undefined') {
      try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
      } catch (error) {
          logger.error('User denied account access');
          return null;
      }
      
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(ApyMapper, addresses.ApyMapper);
      return new Promise((resolve, reject) => {
          contract.methods.owner().call()
              .then(resp => resolve(resp))
              .catch(error => reject(error));
      });
  } else {
      logger.error('MetaMask is not installed.');
      return null;
  }
};
/**
 * Gets the owner of the APY contract.
 *
 * This function checks if the Ethereum provider (MetaMask) is available and requests account access.
 * If access is granted, it creates a Web3 instance and connects to the APY contract using the specified ABI and address.
 * It then retrieves and returns the owner of the contract.
 *
 * @returns {Promise<string|null>} The address of the contract owner if successful, or null if an error occurs.
 */
export const getAPYContractOwner = async () => {
  if (typeof window.ethereum !== 'undefined') {
      try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
      } catch (error) {
        logger.error('User denied account access');
        return null;
      }
      
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(ApyContractAbi, addresses.APYContract);
      return new Promise((resolve, reject) => {
          contract.methods.manager().call()
              .then(resp => resolve(resp))
              
              .catch(error => reject(error));
      });
  } else {
      logger.error('MetaMask is not installed.');
      return null;
  }
};



export const updatedApy = async (constraceAddress,apyAdjustedValue,daysecond) => {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
      window.web3 = new Web3(provider);
  } else {
      window.web3 = new Web3(window.ethereum);
  }

  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
              //  const daysecond = 60
               const value =apyAdjustedValue/365
                const contract = new ethers.Contract(constraceAddress, contract_api, signer);
                const web3 = new Web3(window.ethereum);
                const amountInWei = web3.utils.toWei(value, "ether");
                   contract.updateAPY(amountInWei,daysecond,{
                    // gasLimit: GAS_LIMIT,
                  }
                    
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};
/**
 * Withdraws the collected fee from the specified contract.
 *
 * This function detects the Ethereum provider and creates a Web3 provider instance.
 * It then connects to the specified contract address using the provided ABI and signer.
 * The function calculates the adjusted APY value, converts it to Wei, and withdraws it
 * from the contract to the specified master wallet address. The transaction is sent and awaited for confirmation.
 *
 * @param {string} constraceAddress - The address of the contract from which the fee will be withdrawn.
 * @param {string} apyAdjustedValue - The APY value to be adjusted and withdrawn.
 * @returns {Promise<object>} The transaction response object if the transaction is successful.
 * @throws Will throw an error if unable to withdraw the collected fee from the contract.
 */
export const getwithdrawCollectedFee = async (constraceAddress,apyAdjustedValue,Masterwalletaddress) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);


  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                const contract = new ethers.Contract(constraceAddress, contract_api, signer);
                const web3 = new Web3(window.ethereum);
                const amountInWei = web3.utils.toWei(apyAdjustedValue, "ether");
                   contract.withdrawCollectedFee(Masterwalletaddress,amountInWei,{
                    gasLimit: GAS_LIMIT,
                  }
                    
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};
/**
 * Sets the stability fee in the specified contract.
 *
 * This function detects the Ethereum provider and creates a Web3 provider instance.
 * It then connects to the specified contract address using the provided ABI and signer.
 * The function calculates the adjusted APY value, converts it to Wei, and sets it as the
 * stability fee in the contract. The transaction is sent and awaited for confirmation.
 *
 * @param {string} constraceAddress - The address of the contract where the stability fee will be set.
 * @param {string} apyAdjustedValue - The APY value to be adjusted and set as the stability fee.
 * @returns {Promise<object>} The transaction response object if the transaction is successful.
 * @throws Will throw an error if unable to set the stability fee in the contract.
 */
export const GetStabilityFee = async (constraceAddress,apyAdjustedValue) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);

  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                const contract = new ethers.Contract(constraceAddress, contract_api, signer);
                const web3 = new Web3(window.ethereum);
                   contract.setStabilityFee(apyAdjustedValue,{
                    // gasLimit: GAS_LIMIT,
                  }
                    
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};

/**
 * Retrieves the daySeconds value from the APY contract.
 *
 * This function detects the Ethereum provider and creates a Web3 provider instance.
 * It then connects to the APY contract using the specified address and ABI, and retrieves
 * the daySeconds value, which is the number of seconds in a day according to the contract.
 *
 * @returns {Promise<number>} The number of seconds in a day as defined in the APY contract.
 * @throws Will throw an error if unable to retrieve the daySeconds value from the contract.
 */
export const getdaysecond = async () => {
  const provider = await detectEthereumProvider();
  const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);

  const signer = web3Provider.getSigner();

  const contract = new ethers.Contract(addresses.APYContract, apy_api, signer);

  try {
    const result = await contract.daySeconds();
    await result;
    return result;
  } catch (error) {
    throw error;
  }
};

/**
 * Function to set an authentic user in the ApyMapper contract.
 * @param {number|string} apyAdjustedValue - The value to be set for the authentic user.
 * @returns {Promise} - A promise that resolves with the transaction response or rejects with an error.
 */
export const getsetAuthenticUser = async (apyAdjustedValue) => {
  const provider = await detectEthereumProvider();
  if (provider !== window.ethereum) {
      window.web3 = new Web3(provider);
  } else {
      window.web3 = new Web3(window.ethereum);
  }

  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                      // Initialize the contract with its address, ABI, and signer
                const contract = new ethers.Contract(addresses.ApyMapper, ApyMapper, signer);
                      // Call the setAuthenticUser function on the contract with the provided value
                   contract.setAuthenticUser(apyAdjustedValue,{
                  }
                    
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};
/**
 * Function to set an authentic user in the txHistory contract.
 * @param {number|string} apyAdjustedValue - The value to be set for the authentic user.
 * @returns {Promise} - A promise that resolves with the transaction response or rejects with an error.
 */
export const getsetAuthenticUserTxHistory = async (apyAdjustedValue) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);
  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                      // Initialize the contract with its address, ABI, and signer
                const contract = new ethers.Contract(addresses.TransactionHistory, TransactionHistoryAbi, signer);
                      // Call the setAuthenticUser function on the contract with the provided value
                   contract.setAuthenticUser(apyAdjustedValue,{
                  }
                    
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};
/**
 * Function to set an authentic user in the vault contract.
 * @param {number|string} apyAdjustedValue - The value to be set for the authentic user.
 * @returns {Promise} - A promise that resolves with the transaction response or rejects with an error.
 */
export const getsetAuthenticUservault = async (apyAdjustedValue) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);
  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                      // Initialize the contract with its address, ABI, and signer
                const contract = new ethers.Contract(addresses.vault, vault_abi, signer);
                      // Call the setAuthenticUser function on the contract with the provided value
                   contract.setAuthenticUser(apyAdjustedValue,{
                  }
                    
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};
/**
 * Function to set an authentic user in the vault contract.
 * @param {number|string} apyAdjustedValue - The value to be remove for the authentic user.
 * @returns {Promise} - A promise that resolves with the transaction response or rejects with an error.
 */
export const getsetremovevault = async (apyAdjustedValue) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);
  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                      // Initialize the contract with its address, ABI, and signer
                const contract = new ethers.Contract(addresses.vault, vault_abi, signer);
                      // Call the removeAuthenticUser function on the contract with the provided value
                   contract.removeAuthenticUser(apyAdjustedValue,{
                  }
                    
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};
/**
 * Function to set an Master wallet  in the Liquidation contract.
 * @param {number|string} apyAdjustedValue - The value to be set for the master wallet.
 * @returns {Promise} - A promise that resolves with the transaction response or rejects with an error.
 */
export const getMasterWallet = async (apyAdjustedValue) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);
  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                      // Initialize the contract with its address, ABI, and signer
                const contract = new ethers.Contract(addresses.Liquidation, liquidation_abi, signer);
                      // Call the setAuthenticUser function on the contract with the provided value
                   contract.setMasterWallet(apyAdjustedValue,{
                  }
                    
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};
/**
 * Function to set an authentic user in the txHistory contract.
 * @param {number|string} apyAdjustedValue -  The value to be removed as an authentic user.
 * @returns {Promise} - Resolves with the transaction result or rejects with an error.
 */
export const getremoveAuthenticUserTxHistory = async (apyAdjustedValue) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);
  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                      // Initialize the contract with its address, ABI, and signer
                const contract = new ethers.Contract(addresses.TransactionHistory, TransactionHistoryAbi, signer);
                      // Call the setAuthenticUser function on the contract with the provided value
                   contract.removeAuthenticUser(apyAdjustedValue,{
                  }
                    
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};
/**
 * Function to set an authentic user in the ERUSDJoin contract.
 * @param {number|string} apyAdjustedValue - The value to be set for the authentic user.
 * @returns {Promise} - A promise that resolves with the transaction response or rejects with an error.
 */
export const getsetAuthenticUserERUSDJoin = async (apyAdjustedValue) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);

  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                      // Initialize the contract with its address, ABI, and signer
                const contract = new ethers.Contract(addresses.ERUSDJoin, ErusdJoin, signer);
                      // Call the setAuthenticUser function on the contract with the provided value
                   contract.setAuthenticUser(apyAdjustedValue,{
                  }
                    
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};
/**
 * Function to set an authentic user in the ETHJoin contract.
 * @param {number|string} apyAdjustedValue - The value to be set for the authentic user.
 * @returns {Promise} - A promise that resolves with the transaction response or rejects with an error.
 */
export const getsetAuthenticUserETHJoin = async (apyAdjustedValue) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);

  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                      // Initialize the contract with its address, ABI, and signer
                const contract = new ethers.Contract(addresses.ETHJoin, EthJoinAbi, signer);
                      // Call the setAuthenticUser function on the contract with the provided value
                   contract.setAuthenticUser(apyAdjustedValue,{
                  }
                    
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};
/**
 * Remove an authentic user from the ERUSDJoin contract.
 * @param {string} apyAdjustedValue - The value to be removed as an authentic user.
 * @returns {Promise} - Resolves with the transaction result or rejects with an error.
 */
export const getremoveAuthenticUserERUSDJoin = async (apyAdjustedValue) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);


  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                const contract = new ethers.Contract(addresses.ERUSDJoin, ErusdJoin, signer);
                   contract.removeAuthenticUser(apyAdjustedValue,{
                  }
                    
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
            
  });
};
/**
 * Remove an authentic user from the ETHJoin contract.
 * @param {string} apyAdjustedValue - The value to be removed as an authentic user.
 * @returns {Promise} - Resolves with the transaction result or rejects with an error.
 */
export const getremoveAuthenticUserEThJoin = async (apyAdjustedValue) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);


  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                const contract = new ethers.Contract(addresses.ETHJoin, EthJoinAbi, signer);
                   contract.removeAuthenticUser(apyAdjustedValue,{
                  }
                    
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
            
  });
};

/**
 * Set an authentic user in the ERUSDToken contract.
 * @param {string} apyAdjustedValue - The value to be set as an authentic user.
 * @returns {Promise} - Resolves with the transaction result or rejects with an error.
 */
export const getsetAuthenticUserErusd = async (apyAdjustedValue) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);


  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                const contract = new ethers.Contract(addresses.ERUSDToken, ERUSDAbi, signer);
                   contract.setAuthenticUser(apyAdjustedValue,{
                  }
                    
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};
/**
 * Remove an authentic user from the ERUSDToken contract.
 * @param {string} apyAdjustedValue - The value to be removed as an authentic user.
 * @returns {Promise} - Resolves with the transaction result or rejects with an error.
 */
export const getRemoveErusdToken = async (apyAdjustedValue) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);


  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                const contract = new ethers.Contract(addresses.ERUSDToken, ERUSDAbi, signer);
                   contract.removeAuthectedPerson(apyAdjustedValue,{
                  }
                    
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};
/**
 * Remove an authentic user from the ApyMapper contract.
 * @param {string} apyAdjustedValue - The value to be removed as an authentic user.
 * @returns {Promise} - Resolves with the transaction result or rejects with an error.
 */
export const getremoveAuthenticUser = async (apyAdjustedValue) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);


  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                const contract = new ethers.Contract(addresses.ApyMapper, ApyMapper, signer);
                   contract.removeAuthenticUser(apyAdjustedValue,{
                  }
                    
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
            
  });
};

/**
 * Function to get the total fee collected from a smart contract.
 * 
 * @returns {Promise<BigNumber>} The total fee collected, wrapped in a Promise.
 * @throws Throws an error if there's an issue fetching the data from the contract.
 */
export const GettotalfeeCollected = async () => {
  const provider = await detectEthereumProvider();
  const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);

  const signer = web3Provider.getSigner();

  const contract = new ethers.Contract(addresses.vault, vault_abi, signer);
  try {
    const result = await contract.totalfeeCollected();
    await result;
    return result;
  } catch (error) {
    throw error;
  }
};

/**
 * Function to get the total fee liquidationPenalty from a smart contract.
 * 
 * @returns {Promise<BigNumber>} The total fee liquidationPenalty, wrapped in a Promise.
 * @throws Throws an error if there's an issue fetching the data from the contract.
 */
export const GetliquidationPenalty = async () => {
  const provider = await detectEthereumProvider();
  const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);

  const signer = web3Provider.getSigner();

  const contract = new ethers.Contract(addresses.Liquidation, liquidation_abi, signer);
  try {
    const result = await contract.liquidationPenalty();
    await result;
    return result;
  } catch (error) {
    throw error;
  }
};

export const GetliquidationMaster = async () => {
  const provider = await detectEthereumProvider();
  const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);

  const signer = web3Provider.getSigner();

  const contract = new ethers.Contract(addresses.Liquidation, liquidation_abi, signer);
  try {
    const result = await contract.masterWallet();
    await result;
    return result;
  } catch (error) {
    throw error;
  }
};

/**
 * Function to get the total fee Incentive from a smart contract.
 * 
 * @returns {Promise<BigNumber>} The total fee Get Incentive, wrapped in a Promise.
 * @throws Throws an error if there's an issue fetching the data from the contract.
 */
export const GetIncentiveFee= async () => {
  const provider = await detectEthereumProvider();
  const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);

  const signer = web3Provider.getSigner();

  const contract = new ethers.Contract(addresses.Liquidation, liquidation_abi, signer);
  try {
    const result = await contract.incentiveFee();
    await result;
    return result;
  } catch (error) {
    throw error;
  }
};


/**
 * Withdraws the collected fee from the specified contract.
 *
 * This function detects the Ethereum provider and creates a Web3 provider instance.
 * It then connects to the specified contract address using the provided ABI and signer.
 * The function calculates the adjusted APY value, converts it to Wei, and withdraws it
 * @param {string} constraceAddress - The address of the contract from which the fee will be withdrawn.
 * @param {string} apyAdjustedValue - The APY value to be adjusted and withdrawn.
 *  * @param {string} destinationAddress - The addresss of destination and withdrawn.

 * @returns {Promise<object>} The transaction response object if the transaction is successful.
 * @throws Will throw an error if unable to withdraw the collected fee from the contract.
 */
export const getwithdrawCollectedFeeETHjoin = async (constraceAddress,apyAdjustedValue,destinationAddress) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);


  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                const contract = new ethers.Contract(constraceAddress, EthJoinAbi, signer);
                const web3 = new Web3(window.ethereum);
                const amountInWei = web3.utils.toWei(apyAdjustedValue, "ether");
                   contract.withdrawFee(destinationAddress,amountInWei,{
                    // gasLimit: GAS_LIMIT,
                  }
                    
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};

/**
 * Withdraws the Currency Amount from the specified contract.
 *
 * This function detects the Ethereum provider and creates a Web3 provider instance.
 * It then connects to the specified contract address using the provided ABI and signer.
 * The function calculates the adjusted APY value, converts it to Wei, and withdraws it
 * @param {string} constraceAddress - The address of the contract from which the fee will be withdrawn.
 * @param {string} apyAdjustedValue - The APY value to be adjusted and withdrawn.
 *  * @param {string} destinationAddress - The addresss of destination and withdrawn.

 * @returns {Promise<object>} The transaction response object if the transaction is successful.
 * @throws Will throw an error if unable to withdraw the collected fee from the contract.
 */
export const getwithdrawCollectedCurrencyAmount = async (constraceAddress,apyAdjustedValue,destinationAddress) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);


  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                const contract = new ethers.Contract(constraceAddress, EthJoinAbi, signer);
                const web3 = new Web3(window.ethereum);
                const amountInWei = web3.utils.toWei(apyAdjustedValue, "ether");
                   contract.EmergencyWithdrawCurrency(destinationAddress,amountInWei,{
                    // gasLimit: GAS_LIMIT,
                  }
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};
/**
 * Withdraws the Currency Amount from the specified contract.
 *
 * This function detects the Ethereum provider and creates a Web3 provider instance.
 * It then connects to the specified contract address using the provided ABI and signer.
 * The function calculates the adjusted APY value, converts it to Wei, and withdraws it
 * @param {string} constraceAddress - The address of the contract from which the fee will be withdrawn.
 * @param {string} apyAdjustedValue - The APY value to be adjusted and withdrawn.
 *  * @param {string} destinationAddress - The addresss of destination and withdrawn.

 * @returns {Promise<object>} The transaction response object if the transaction is successful.
 * @throws Will throw an error if unable to withdraw the collected fee from the contract.
 */
export const getwithdrawLiquidationPenaltyAmount = async (amount) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);


  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                const contract = new ethers.Contract(addresses.Liquidation, liquidation_abi, signer);
                const web3 = new Web3(window.ethereum);
                // const amountInWei = web3.utils.toWei(amount, "ether");
                   contract.setLiquidationPenalty(amount,{
                    // gasLimit: GAS_LIMIT,
                  }
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};
/**
 * Withdraws the Currency Amount from the specified contract.
 *
 * This function detects the Ethereum provider and creates a Web3 provider instance.
 * It then connects to the specified contract address using the provided ABI and signer.
 * The function calculates the adjusted APY value, converts it to Wei, and withdraws it
 * @param {string} amount - The APY value to be adjusted and withdrawn.

 * @returns {Promise<object>} The transaction response object if the transaction is successful.
 * @throws Will throw an error if unable to withdraw the collected fee from the contract.
 */
export const getIncentiveFeeAmount = async (amount) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);


  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                const contract = new ethers.Contract(addresses.Liquidation, liquidation_abi, signer);
                const web3 = new Web3(window.ethereum);
                const amountInWei = web3.utils.toWei(amount, "ether");
                   contract.setIncentiveFee(amount,{
                    // gasLimit: GAS_LIMIT,
                  }
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};

/**
 * Function to set an authentic user in the ETHJoin contract.
 * @param {number|string} apyAdjustedValue - The value to be set for the authentic user.
 * @returns {Promise} - A promise that resolves with the transaction response or rejects with an error.
 */
export const getsetAuthenticUserSwap = async (apyAdjustedValue) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);

  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                      // Initialize the contract with its address, ABI, and signer
                const contract = new ethers.Contract(addresses.Swap, SwapAbi, signer);
                      // Call the setAuthenticUser function on the contract with the provided value
                   contract.setAuthenticUser(apyAdjustedValue,{
                  }
                    
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};
/**
 * Remove an authentic user from the ETHJoin contract.
 * @param {string} apyAdjustedValue - The value to be removed as an authentic user.
 * @returns {Promise} - Resolves with the transaction result or rejects with an error.
 */
export const getremoveAuthenticUserSwap = async (apyAdjustedValue) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);


  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                const contract = new ethers.Contract(addresses.Swap, SwapAbi, signer);
                   contract.removeAuthenticUser(apyAdjustedValue,{
                  }
                    
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
            
  });
};

export const GetEmergancyCurrency= async () => {
  const provider = await detectEthereumProvider();
  const web3Provider = provider !== window.ethereum ? new ethers.providers.Web3Provider(provider) : new ethers.providers.Web3Provider(window.ethereum);

  const signer = web3Provider.getSigner();

  const contract = new ethers.Contract(addresses.ETHJoin, EthJoinAbi, signer);
  try {
    const result = await contract.getContractBalance();
    await result;
    return result;
  } catch (error) {
    throw error;
  }
};

/**
 * Withdraws the collected fee from the specified contract.
 *
 * This function detects the Ethereum provider and creates a Web3 provider instance.
 * It then connects to the specified contract address using the provided ABI and signer.
 * The function calculates the adjusted APY value, converts it to Wei, and withdraws it
 * @param {string} constraceAddress - The address of the contract from which the fee will be withdrawn.
 * @param {string} apyAdjustedValue - The APY value to be adjusted and withdrawn.
 *  * @param {string} destinationAddress - The addresss of destination and withdrawn.

 * @returns {Promise<object>} The transaction response object if the transaction is successful.
 * @throws Will throw an error if unable to withdraw the collected fee from the contract.
 */
export const getwithdrawCollectedFeeSwap = async (constraceAddress,apyAdjustedValue,destinationAddress) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);


  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                const contract = new ethers.Contract(constraceAddress, SwapAbi, signer);
                const web3 = new Web3(window.ethereum);
                const amountInWei = web3.utils.toWei(apyAdjustedValue, "ether");
                   contract.withdrawFee(destinationAddress,amountInWei,{
                    // gasLimit: GAS_LIMIT,
                  }
                    
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};

/**
 * Withdraws the Currency Amount from the specified contract.
 *
 * This function detects the Ethereum provider and creates a Web3 provider instance.
 * It then connects to the specified contract address using the provided ABI and signer.
 * The function calculates the adjusted APY value, converts it to Wei, and withdraws it
 * @param {string} constraceAddress - The address of the contract from which the fee will be withdrawn.
 * @param {string} apyAdjustedValue - The APY value to be adjusted and withdrawn.
 *  * @param {string} destinationAddress - The addresss of destination and withdrawn.

 * @returns {Promise<object>} The transaction response object if the transaction is successful.
 * @throws Will throw an error if unable to withdraw the collected fee from the contract.
 */
export const getEmergencyWithdrawCurrencySwap = async (constraceAddress,apyAdjustedValue,destinationAddress) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);

  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                const contract = new ethers.Contract(constraceAddress, SwapAbi, signer);
                const web3 = new Web3(window.ethereum);
                const amountInWei = web3.utils.toWei(apyAdjustedValue, "ether");
                   contract.emergencyWithdrawCurrency(destinationAddress,amountInWei,{
                    gasLimit: GAS_LIMIT,
                  }
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};




/**
 * Withdraws the Token Amount from the specified contract.
 *
 * This function detects the Ethereum provider and creates a Web3 provider instance.
 * It then connects to the specified contract address using the provided ABI and signer.
 * The function calculates the adjusted APY value, converts it to Wei, and withdraws it
 * @param {string} constraceAddress - The address of the contract from which the fee will be withdrawn.
 *  * @param {string} tokenaddress - The address of the contract from which the fee will be withdrawn.
 * @param {string} apyAdjustedValue - The APY value to be adjusted and withdrawn.
 *  * @param {string} destinationAddress - The addresss of destination and withdrawn.

 * @returns {Promise<object>} The transaction response object if the transaction is successful.
 * @throws Will throw an error if unable to withdraw the collected fee from the contract.
 */
export const getwithdrawCollectedTokenSwapAmount = async (constraceAddress,apyAdjustedValue,destinationAddress,tokenaddress) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);


  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                const contract = new ethers.Contract(constraceAddress, SwapAbi, signer);
                const web3 = new Web3(window.ethereum);
                const amountInWei = web3.utils.toWei(apyAdjustedValue, "ether");
                   contract.emergencyWithdrawToken(tokenaddress,destinationAddress,amountInWei,{
                    // gasLimit: GAS_LIMIT,
                  }
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};


export const getwithdrawCollectedTokenETHAmount = async (constraceAddress,apyAdjustedValue,destinationAddress,tokenaddress) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);

  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                const contract = new ethers.Contract(addresses.ETHJoin, EthJoinAbi, signer);
                const web3 = new Web3(window.ethereum);
                const amountInWei = web3.utils.toWei(apyAdjustedValue, "ether");
                   contract.EmergencyWithdrawToken(tokenaddress,destinationAddress,amountInWei,{
                    // gasLimit: GAS_LIMIT,
                  }
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};


/**
 * Withdraws the Currency Amount from the specified contract.
 *
 * This function detects the Ethereum provider and creates a Web3 provider instance.
 * It then connects to the specified contract address using the provided ABI and signer.
 * The function calculates the adjusted APY value, converts it to Wei, and withdraws it
 * @param {string} amount - The APY value to be adjusted and withdrawn.

 * @returns {Promise<object>} The transaction response object if the transaction is successful.
 * @throws Will throw an error if unable to withdraw the collected fee from the contract.
 */
export const getOracleRate = async (amount) => {
  const provider = await detectEthereumProvider();
  window.web3 = provider !== window.ethereum ? new Web3(provider) : new Web3(window.ethereum);


  return new Promise(async (resolve, reject) => {
      
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider1.getSigner();
                const contract = new ethers.Contract(addresses.oraclePrice, oraclePriceAbi, signer);
                const web3 = new Web3(window.ethereum);
                const amountInWei = web3.utils.toWei(amount, "ether");
                   contract.setETHCRate(amountInWei,{
                    // gasLimit: GAS_LIMIT,
                  }
                   ).then(async (res) => {
                    await res.wait().then((x) => {
                          resolve(res);
                      }).catch((error) => {
                          reject(error);
                      });
                  }).catch((error) => {

                      reject(error);
                  });
              
          
          
  });
};
import React, { useRef } from "react";
import { Container, Row, Col, Dropdown, Nav } from "react-bootstrap";
import footerlogo from "../Assets/Images/Logo/Footer-logo.png";

function Footer() {
  
  return (
    <>
      <Container className="footer" fluid>
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" className="text-center">
                <img src={footerlogo} alt="Footer Logo" />
               
                <div className="footer-social-list">
                  </div>
                <div className="flex-div-md align-items-center">
                  
                  <p className="m-0">
                  Copyright © 2024 All rights reserved. Veritas Ecosystems.                   </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </>
  );
}
export default Footer;

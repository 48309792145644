import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Button,Form ,Tabs,Tab} from "react-bootstrap";

import {
  GetEmergancyCurrency,
  GetIncentiveFee,
  GetStabilityFee,
  GetliquidationMaster,
  GetliquidationPenalty,
  GettotalfeeCollected,
  LatestAPYFee,
  getAPYContractOwner,
  getERUSDTokenOwner,
  getETHJoinOwner,
  getEmergencyWithdrawCurrencySwap,
  getErusdOwner,
  getIncentiveFeeAmount,
  getLiquidationOwner,
  getMasterWallet,
  getOracleRate,
  getRemoveErusdToken,
  getRenewOwner, getTransactionHistoryOwner, getaypcontractOwner, getbalanceOf, getbalanceOfSwap, getdaysecond, getoraclePriceOwner, getremoveAuthenticUserERUSDJoin, getremoveAuthenticUserEThJoin, getremoveAuthenticUserSwap, getremoveAuthenticUserTxHistory, getsetAuthenticUserERUSDJoin, getsetAuthenticUserETHJoin, getsetAuthenticUserErusd, getsetAuthenticUserSwap, getsetAuthenticUserTxHistory, getsetAuthenticUservault, getsetremovevault, getvaultOwner, getwithdrawCollectedCurrencyAmount, getwithdrawCollectedFee, getwithdrawCollectedFeeETHjoin, getwithdrawCollectedFeeSwap, getwithdrawCollectedTokenAmount, getwithdrawCollectedTokenETHAmount, getwithdrawCollectedTokenSwapAmount, getwithdrawLiquidationPenaltyAmount, stabilityFee, updatedApy,
} from "../utils/Web3/metamask";
import addresses, { ChainIds, ERUSDToken, MAX_DECIMAL_PLACES, MAX_INPUT_LENGTH, NUMERIC_DECIMAL_REGEX, addressesRegex, inputRegex, tabs, validateAddress } from "../Const/const";
import { vault } from "../Const/const";
import { ETHJoin } from "../Const/const";
import { PulseLoader } from "react-spinners";
import laoder from "../Assets/Images/laoder.gif";
import { NotificationTypes, showNotification } from "../utils/Toast/toast";
import {
  
  useChainId,
  
} from "@thirdweb-dev/react";
import FormComponent from "../Components/FormComponent";
import TabsComponent from "../Components/Tabs";
import { getsetAuthenticUser } from "../utils/Web3/metamask";
import { getremoveAuthenticUser } from "../utils/Web3/metamask";
function Home({
  address,
 
}) {
  const [Owner, setOwner] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [apyamount, setApyPercentage] = useState('');
  const [StabilityFee, setStabilityFee] = useState('');
  const [withdrawFee, setwithdrawFee] = useState(null);
  const [TotalStabiltyFee, setTotalStabiltyFee] = useState(null);
  const [withdrawFeeETHJoin, setwithdrawFeeETHJoin] = useState(null);
  const [withdrawFeeSwap, setwithdrawFeeSwap] = useState(null);
  const [CurrencyAmount, setCurrencyAmount] = useState(null);
  const [TokenAmount, setTokenAmount] = useState(null);
  const [SwapTokenAmount, setTokenSwapAmount] = useState(null);
  const [TotalTokenBalance, setTotalTokenBalance] = useState(null);
  const [CurrencyAmountSwap, setCurrencyAmountSwap] = useState(null);
  const [LiquidationPenalty, setLiquidationPenalty] = useState(null);
  const [TokenSwapAddress, setTokenSwapAddress] = useState('');
  const [OracleRate, setOracleRate] = useState(null);
  const [IncentiveFee, setIncentiveFee] = useState(null);
  const [Loder, setLoader] = useState(false);
  const [isLoder, setisLoader] = useState(false);
  const [daysecond, setdaysecond] = useState(null);
  const [liquidationPenaltyfee, setliquidationPenaltyfee] = useState(null);
  const [TotalIncentiveFee, setTotalIncentiveFee] = useState(null);
  const [Masterwalletaddress, setMasterwalletaddress] = useState('');
  const [EmergencyCurrency, setEmergencyCurrency] = useState(null);
  const [balanceOf, setbalanceOf] = useState('');
  const [TokenAmountSwap, setTokenAmountSwap] = useState('')
  const [destinationAddress, setdestinationAddress] = useState('');
  const [destinationSwapAddress, setdestinationSwapAddress] = useState('');
  const [CurrencyAddress, setCurrencyAddress] = useState('');
  const [SwapCurrencyAddress, setCurrencySwapAddress] = useState('');
  const [TokenAddress, setTokenAddress] = useState('');
  const [Masteraddress, setMasteraddress] = useState('');
  const [SellerusdFee, setSellErusdfee] = useState(0);
   const [validation, setValidation] = useState({
    isValid: true,
    IsValiddestination: true, // Initial value for IsValiddestination
    isValidERUSDJoin: true,
    isValidETHJoin: true,
    isValidSwap: true,
    isValidtxHistory: true,
    isValidvault: true,
    isValidMaster: true,
    isValidErusd: true,
    removeisValid: true,
    removeisValidERUSDJoin: true,
    removeisValidETHJoin: true,
    removeisValidtxHistory: true,
    removeisValidvault: true,
    removeisValidSwap: true,
    IsValiddestination: true,
    IsValiddestinationSwap: true,
    IsValidCurrencySwap: true,
    IsValidCurrency: true,
    IsValidToken: true,
    IsValidTokenSwap: true,
    removeisValidErusd: true,
    isValiddata: true,
    isValidbalanceOf: true,
    validationBalance: '',
    isValidbalanceOfApy: true,
    validationBalanceApy: '',
    isValidbalanceWithdraw: true,
    validationBalanceWithdraw: '',
    validationMessage: '',
    validationTokenSwap: '',
    validationSwap: '',
    validationEmergencyCurrency: ''
  });


  const chainId = useChainId();
 useEffect(()=>{
  if (address) {
    getOwner()
    Getdaysecond()
    GettotalCollected()
    liquidationPenalty()
    GetTotalIncentiveFee()
    GetEmergencyCurrencyFee()
    GetBalanceOFErusd()
    GetBalanceOFSwap()
    GetMasteraddress()
    GetStabilityPer()
    LatesApyDeatil()

  }
 },[address,chainId])
 useEffect(() => {
  if (address===undefined) {
    setIsAdmin(false);
  }
}, [address]);
/**
 * Retrieves the GetTotal Incentive Fee  from the liquidation contract asynchronously.
 * Converts the result to a decimal integer and updates the component state with the day seconds value.
 * If an error occurs during the retrieval process, it catches the error without any further action.
 *
 * @returns {void}
 */
const GetBalanceOFErusd = async() => {
  try {
    const result = await getbalanceOf(addresses.ERUSDToken,address)
     const decimalResult =parseInt(result?._hex, 16); // Convert hex result to decimal integer
     const EthResult = decimalResult / 10 ** 18;
     setTotalTokenBalance(EthResult)
  }
  catch(err) {
  }
}
const GetMasteraddress = async() => {
  try {
    const result = await GetliquidationMaster()
    setMasterwalletaddress(result)
   
  }
  catch(err) {
  }
}
const GetBalanceOFSwap = async() => {
  try {
    const result = await getbalanceOfSwap(addresses.ERUSDToken,address)
     const decimalResult =parseInt(result?._hex, 16); // Convert hex result to decimal integer
     const EthResult = decimalResult / 10 ** 18;
     setTokenAmountSwap(EthResult)

    //  setTotalTokenBalance(EthResult)
  }
  catch(err) {
  }
}
/**
 * Retrieves the GetTotal Incentive Fee  from the liquidation contract asynchronously.
 * Converts the result to a decimal integer and updates the component state with the day seconds value.
 * If an error occurs during the retrieval process, it catches the error without any further action.
 *
 * @returns {void}
 */
const GetTotalIncentiveFee = async() => {
  try {
    const result = await GetIncentiveFee()
     const decimalResult =parseInt(result?._hex, 16); // Convert hex result to decimal integer
     setTotalIncentiveFee(decimalResult)
  }
  catch(err) {
  }
}
const LatesApyDeatil = async () => {
  LatestAPYFee(addresses.APYContract)
    .then((x) => {
      const decimalResult = parseInt(x[1]?._hex, 16);
      const EthResult = decimalResult / 10 ** 18;
      const annualFee = EthResult * 365; // Calculate annual fee in percentage
      const roundedFee = Math.round(annualFee * 100) / 100;
      const formattedFee = roundedFee.toFixed(1);

      // Round up to 2 decimal places and convert to percentage format
      // const formattedFee = (Math.round(annualFee * 10000) / 100).toFixed(2) + '%';
      setSellErusdfee(formattedFee)
      // setSellErusdfee(EthResult * 365);
    })
    .catch((err) => { });
};
const GetStabilityPer= async() => {
  try {
    const result = await stabilityFee(addresses.contractAddress)
     const decimalResult =parseInt(result?._hex, 16); // Convert hex result to decimal integer
     setTotalStabiltyFee(decimalResult)
  }
  catch(err) {
  }
}
/**
 * Retrieves the GetTotal Incentive Fee  from the liquidation contract asynchronously.
 * Converts the result to a decimal integer and updates the component state with the day seconds value.
 * If an error occurs during the retrieval process, it catches the error without any further action.
 *
 * @returns {void}
 */
const GetEmergencyCurrencyFee = async() => {
  try {
    const result = await GetEmergancyCurrency()
     const decimalResult =parseInt(result?._hex, 16); // Convert hex result to decimal integer
    const EthResult = decimalResult / 10 ** 18;  // Convert decimal Result to Eth 
    setEmergencyCurrency(EthResult)
  }
  catch(err) {
  }
}
/**
 * Retrieves the liquidation Penalty from the liquidation contract asynchronously.
 * Converts the result to a decimal integer and updates the component state with the day seconds value.
 * If an error occurs during the retrieval process, it catches the error without any further action.
 *
 * @returns {void}
 */
const liquidationPenalty = async() => {
  try {
    const result = await GetliquidationPenalty()
     const decimalResult =parseInt(result?._hex, 16); // Convert hex result to decimal integer
     setliquidationPenaltyfee(decimalResult)
  }
  catch(err) {
  }
}
/**
 * Retrieves the day seconds from the APY contract asynchronously.
 * Converts the result to a decimal integer and updates the component state with the day seconds value.
 * If an error occurs during the retrieval process, it catches the error without any further action.
 *
 * @returns {void}
 */
const Getdaysecond = async() => {
  try {
    const result = await getdaysecond()
     const decimalResult =parseInt(result?._hex, 16); // Convert hex result to decimal integer
     setdaysecond(decimalResult)
  }
  catch(err) {
  }
}
/**
 * Function to fetch and process the total fee collected from a smart contract,
 * converting it to Ether and updating a state with the result.
 * 
 * @returns {void}
 */
const GettotalCollected = async() => {
  try {
    const result = await GettotalfeeCollected()
     const decimalResult =parseInt(result?._hex, 16);     // Convert the result from hexadecimal string to a decimal integer
     const EthResult = decimalResult / 10 ** 18;         // Convert the decimal result from wei to Ether
     setbalanceOf(EthResult)
  }
  catch(err) {
  }
}
 const getOwner = async () => {
  if (chainId != ChainIds) {
    setIsAdmin(false);
    return;
}
  try {
    const ownerAddress = await getRenewOwner();
    const newOwnerAddress = await getaypcontractOwner()
    const TransactionHistoryowner= await getTransactionHistoryOwner()
    const LiquidationOwner= await getLiquidationOwner()
    const vaultOwner = await getvaultOwner()
    const ETHJoinOwner = await getETHJoinOwner() 
    const ErusdOwner = await getErusdOwner()
    const ERUSDTokenOwner = await getERUSDTokenOwner()
    const oraclePriceOwner = await getoraclePriceOwner()
    const APYContractOwneraddress = await getAPYContractOwner()
  
    const owners = [
      ownerAddress,
      newOwnerAddress,
      TransactionHistoryowner,
      LiquidationOwner,
      vaultOwner,
      ETHJoinOwner,
      ErusdOwner,
      ERUSDTokenOwner,
      oraclePriceOwner,
      APYContractOwneraddress
    ]; 
    setOwner(ownerAddress);
      if (owners.some(owner => owner === address)) {
        setIsAdmin(true);
      
    } else {
      showNotification('You are not an admin',NotificationTypes.ERROR);
      setIsAdmin(false);
    }
  } catch (error) {
    setIsAdmin(false);
    console.error('Error fetching owner:', error);
  }
};
/**
 * Handle change in APY percentage input field.
 * @param {object} e - The event object from the input field.
 */
const handleApyPercentageChange = (e) => {
  
  const value = e.target.value;
  const numericValue = value.replace(/\D/g, ""); 
  if (numericValue === "") {
    setApyPercentage("");
    return;
  }

  // Ensure the value does not exceed 100
  const cappedValue = Math.min(parseInt(numericValue, 10), 100);

  setApyPercentage(cappedValue);
  
  
    
};
/**
 * Handles the change event when the user inputs a new withdraw collected fee value.
 * Cleans the input to only allow numeric and decimal point characters.
 * Limits the number of decimal places and total input length to prevent invalid values.
 * Updates the component state (`withdrawFee`) with the cleaned and validated input.
 *
 * @param {Event} e - The change event object triggered by user input.
 * @returns {void}
 */
const handlewithdrawCollectedFeeChange = (e) => {
  const numericValue = e.target.value;
  const cleanedInput = numericValue.replace(NUMERIC_DECIMAL_REGEX, ""); // Remove non-numeric and non-decimal characters

 
  // Check if there are more than one decimal points
  if (cleanedInput.split(".").length > MAX_DECIMAL_PLACES ) {
    return; // Prevent further processing if input has too many decimal points
  }

  // Check if input length exceeds maximum allowed
  if (cleanedInput.length > MAX_INPUT_LENGTH) {
    return; // Prevent further processing if input length is too long
  }
  const numericValueFloat = parseFloat(cleanedInput);
  // Check if input exceeds balance
  if (numericValueFloat > balanceOf) {
    setValidation((prevState) => ({
      ...prevState,
      isValidbalanceOfApy: false,
      validationBalanceApy: `Amount exceeds balance of ${balanceOf}`,
    }));  } else {
      setValidation((prevState) => ({
        ...prevState,
        isValidbalanceOfApy: true,
        validationBalanceApy: '',
      }));  }
  setwithdrawFee(cleanedInput); // Update state with cleaned and validated input
};
/**
 * Handles the change event when the user inputs a new withdraw collected fee value.
 * Cleans the input to only allow numeric and decimal point characters.
 * Limits the number of decimal places and total input length to prevent invalid values.
 * Updates the component state (`withdrawFee`) with the cleaned and validated input.
 *
 * @param {Event} e - The change event object triggered by user input.
 * @returns {void}
 */
const handlewithdrawETHJoinChange = (e) => {
  const numericValue = e.target.value;
  const cleanedInput = numericValue.replace(NUMERIC_DECIMAL_REGEX, ""); // Remove non-numeric and non-decimal characters

  // Check if there are more than one decimal points
  if (cleanedInput.split(".").length > MAX_DECIMAL_PLACES) {
    return; // Prevent further processing if input has too many decimal points
  }

  // Check if input length exceeds maximum allowed
  if (cleanedInput.length > MAX_INPUT_LENGTH) {
    return; // Prevent further processing if input length is too long
  }
  const numericValueFloat = parseFloat(cleanedInput);

  // Check if input exceeds balance
  if (numericValueFloat > balanceOf) {
    setValidation((prevState) => ({
      ...prevState,
      isValidbalanceWithdraw: false,
      validationBalanceWithdraw: `Amount exceeds balance of ${balanceOf}`,
    }));  } else {
      setValidation((prevState) => ({
        ...prevState,
        isValidbalanceWithdraw: true,
        validationBalanceWithdraw: '',
      }));  }

  setwithdrawFeeETHJoin(cleanedInput); // Update state with cleaned and validated input
};

/**
 * Handles the change event when the user inputs a new Currency Amount value.
 * Cleans the input to only allow numeric and decimal point characters.
 * Limits the number of decimal places and total input length to prevent invalid values.
 * Updates the component state (`withdrawFee`) with the cleaned and validated input.
 *
 * @param {Event} e - The change event object triggered by user input.
 * @returns {void}
 */
const handleCurrencyAmountChange = (e) => {
  const numericValue = e.target.value;
  const cleanedInput = numericValue.replace(NUMERIC_DECIMAL_REGEX, ""); // Remove non-numeric and non-decimal characters

  // Check if there are more than one decimal points
  if (cleanedInput.split(".").length > MAX_DECIMAL_PLACES ) {
    return; // Prevent further processing if input has too many decimal points
  }

  // Check if input length exceeds maximum allowed
  if (cleanedInput.length > MAX_INPUT_LENGTH) {
    return; // Prevent further processing if input length is too long
  }
  const numericValueFloat = parseFloat(cleanedInput);

  // Check if input exceeds balance
  if (numericValueFloat > EmergencyCurrency) {
    setValidation((prevState) => ({
      ...prevState,
      isValidEmergencyCurrency: false,
      validationEmergencyCurrency: `Amount exceeds balance of ${EmergencyCurrency}`,
    }));  } else {
      setValidation((prevState) => ({
        ...prevState,
        isValidEmergencyCurrency: true,
        validationEmergencyCurrency: '',
      }));  }
  setCurrencyAmount(cleanedInput); // Update state with cleaned and validated input
};
/**
 * Handles the change event when the user inputs a new Currency Amount value.
 * Cleans the input to only allow numeric and decimal point characters.
 * Limits the number of decimal places and total input length to prevent invalid values.
 * Updates the component state (`withdrawFee`) with the cleaned and validated input.
 *
 * @param {Event} e - The change event object triggered by user input.
 * @returns {void}
 */
const handleTokenAmountChange = (e) => {
  const numericValue = e.target.value;
  const cleanedInput = numericValue.replace(NUMERIC_DECIMAL_REGEX, ""); // Remove non-numeric and non-decimal characters

  // Check if there are more than one decimal points
  if (cleanedInput.split(".").length > MAX_DECIMAL_PLACES + 1) {
    return; // Prevent further processing if input has too many decimal points
  }

  // Check if input length exceeds maximum allowed
  if (cleanedInput.length > MAX_INPUT_LENGTH) {
    return; // Prevent further processing if input length is too long
  }
  const numericValueFloat = parseFloat(cleanedInput);

  // Check if input exceeds balance
  if (numericValueFloat > TotalTokenBalance) {
    setValidation((prevState) => ({
      ...prevState,
      isValidTotalTokenBalance: false,
      validationBalance: `Amount exceeds balance of ${TotalTokenBalance}`,
    }));  } else {
      setValidation((prevState) => ({
        ...prevState,
        isValidTotalTokenBalance: true,
        validationBalance: '',
      }));  }

  setTokenAmount(cleanedInput); // Update state with cleaned and validated input
};
/**
 * Handles the change event when the user inputs a new Currency Amount value.
 * Cleans the input to only allow numeric and decimal point characters.
 * Limits the number of decimal places and total input length to prevent invalid values.
 * Updates the component state (`withdrawFee`) with the cleaned and validated input.
 *
 * @param {Event} e - The change event object triggered by user input.
 * @returns {void}
 */
const handleTokenSwapAmountChange = (e) => {
  const numericValue = e.target.value;
  const cleanedInput = numericValue.replace(NUMERIC_DECIMAL_REGEX, ""); // Remove non-numeric and non-decimal characters

  // Check if there are more than one decimal points
  if (cleanedInput.split(".").length > MAX_DECIMAL_PLACES ) {
    return; // Prevent further processing if input has too many decimal points
  }

  // Check if input length exceeds maximum allowed
  if (cleanedInput.length > MAX_INPUT_LENGTH) {
    return; // Prevent further processing if input length is too long
  }
  const numericValueFloat = parseFloat(cleanedInput);

  // Check if input exceeds balance
  if (numericValueFloat > TokenAmountSwap) {
    setValidation((prevState) => ({
      ...prevState,
      isValidTokenSwap: false,
      validationTokenSwap: `Amount exceeds balance of ${TokenAmountSwap}`,
    }));  } else {
      setValidation((prevState) => ({
        ...prevState,
        isValidTokenSwap: true,
        validationTokenSwap: '',
      }));
  }
  setTokenSwapAmount(cleanedInput); // Update state with cleaned and validated input
};
/**
 * Handles the change event when the user inputs a new Currency Amount value.
 * Cleans the input to only allow numeric and decimal point characters.
 * Limits the number of decimal places and total input length to prevent invalid values.
 * Updates the component state (`withdrawFee`) with the cleaned and validated input.
 *
 * @param {Event} e - The change event object triggered by user input.
 * @returns {void}
 */
const handleCurrencyAmountSwapChange = (e) => {
  const numericValue = e.target.value;
  const cleanedInput = numericValue.replace(NUMERIC_DECIMAL_REGEX, ""); // Remove non-numeric and non-decimal characters

  // Check if there are more than one decimal points
  if (cleanedInput.split(".").length > MAX_DECIMAL_PLACES ) {
    return; // Prevent further processing if input has too many decimal points
  }

  // Check if input length exceeds maximum allowed
  if (cleanedInput.length > MAX_INPUT_LENGTH) {
    return; // Prevent further processing if input length is too long
  }
  const numericValueFloat = parseFloat(cleanedInput);

  // Check if input exceeds balance
  if (numericValueFloat > EmergencyCurrency) {
    setValidation((prevState) => ({
      ...prevState,
      isValidSwap: false,
      validationSwap: `Amount exceeds balance of ${EmergencyCurrency}`,
    }));  } else {
      setValidation((prevState) => ({
        ...prevState,
        isValidSwap: true,
        validationSwap: '',
      }));
  }

  setCurrencyAmountSwap(cleanedInput); // Update state with cleaned and validated input
};
/**
 * Handles the change event when the user inputs a new Liquidation Penalty Amount value.
 * Cleans the input to only allow numeric and decimal point characters.
 * Limits the number of decimal places and total input length to prevent invalid values.
 * Updates the component state (`Liquidation Penalty`) with the cleaned and validated input.
 *
 * @param {Event} e - The change event object triggered by user input.
 * @returns {void}
 */
const handleLiquidationPenaltyChange = (e) => {
  const numericValue = e.target.value;
  const cleanedInput = numericValue.replace(NUMERIC_DECIMAL_REGEX, ""); // Remove non-numeric and non-decimal characters

  // Check if there are more than one decimal points
  if (cleanedInput.split(".").length > MAX_DECIMAL_PLACES + 1) {
    return; // Prevent further processing if input has too many decimal points
  }

  // Check if input length exceeds maximum allowed
  if (cleanedInput.length > MAX_INPUT_LENGTH) {
    return; // Prevent further processing if input length is too long
  }

  setLiquidationPenalty(cleanedInput); // Update state with cleaned and validated input
};
/**
 * Handles the change event when the user inputs a new Liquidation Penalty Amount value.
 * Cleans the input to only allow numeric and decimal point characters.
 * Limits the number of decimal places and total input length to prevent invalid values.
 * Updates the component state (`Liquidation Penalty`) with the cleaned and validated input.
 *
 * @param {Event} e - The change event object triggered by user input.
 * @returns {void}
 */
const handleIncentiveFeeChange = (e) => {
  const numericValue = e.target.value;
  const cleanedInput = numericValue.replace(NUMERIC_DECIMAL_REGEX, ""); // Remove non-numeric and non-decimal characters

  // Check if there are more than one decimal points
  if (cleanedInput.split(".").length > MAX_DECIMAL_PLACES ) {
    return; // Prevent further processing if input has too many decimal points
  }

  // Check if input length exceeds maximum allowed
  if (cleanedInput.length > MAX_INPUT_LENGTH) {
    return; // Prevent further processing if input length is too long
  }

  setIncentiveFee(cleanedInput); // Update state with cleaned and validated input
};
/**
 * Handles the change event when the user inputs a new Liquidation Penalty Amount value.
 * Cleans the input to only allow numeric and decimal point characters.
 * Limits the number of decimal places and total input length to prevent invalid values.
 * Updates the component state (`Liquidation Penalty`) with the cleaned and validated input.
 *
 * @param {Event} e - The change event object triggered by user input.
 * @returns {void}
 */
const handleOracleRateChange = (e) => {
  const numericValue = e.target.value;
  const cleanedInput = numericValue.replace(NUMERIC_DECIMAL_REGEX, ""); // Remove non-numeric and non-decimal characters

  // Check if there are more than one decimal points
  if (cleanedInput.split(".").length > MAX_DECIMAL_PLACES ) {
    return; // Prevent further processing if input has too many decimal points
  }

  // Check if input length exceeds maximum allowed
  if (cleanedInput.length > MAX_INPUT_LENGTH) {
    return; // Prevent further processing if input length is too long
  }

  setOracleRate(cleanedInput); // Update state with cleaned and validated input
};
/**
 * Handles the change event when the user inputs a new stability fee value.
 * Validates the input against a regex pattern (`inputRegex`) allowing numeric values and an empty string.
 * Updates the component state (`StabilityFee`) with the validated value if valid.
 *
 * @param {Event} e - The change event object triggered by user input.
 * @returns {void}
 */
const handleStabilityFeeChange = (e) => {

  const value = e.target.value;
  const numericValue = value.replace(/\D/g, ""); // Replace all non-numeric characters
  if (numericValue === "") {
    setStabilityFee("");
    return;
  }

  // Ensure the value does not exceed 100
  const cappedValue = Math.min(parseInt(numericValue, 10), 100);

  setStabilityFee(cappedValue);
};
/**
 * Handle change in set destination user address input field.
 * @param {object} e - The event object from the input field.
 */
const handledestinationChange = (e) => {

  const newAddress = e.target.value;
  setdestinationAddress(newAddress);
  const isValid = validateAddress(newAddress);
  setValidation(prevState => ({
    ...prevState,
    IsValiddestination: isValid, // Update IsValiddestination property
    validationMessage: isValid ? '' : 'Invalid address'
  }));
  
    
};
/**
 * Handle change in set destination user address input field.
 * @param {object} e - The event object from the input field.
 */
const handledestinationCurrencyChange = (e) => {

  const newAddress = e.target.value;
  setCurrencyAddress(newAddress);
  const isValid = validateAddress(newAddress);
  setValidation(prevState => ({
    ...prevState,
    IsValidCurrency:isValid,
  }));
    
};
/**
 * Handle change in set destination user address input field.
 * @param {object} e - The event object from the input field.
 */
const handledestinationTokenChange = (e) => {

  const newAddress = e.target.value;
  setTokenAddress(newAddress);
  const isValid = validateAddress(newAddress);
  setValidation(prevState => ({
    ...prevState,
    IsValidToken:isValid,
  }));
    
};
/**
 * Handle change in set destination user address input field.
 * @param {object} e - The event object from the input field.
 */
const handledestinationTokenSwapChange = (e) => {

  const newAddress = e.target.value;
  setTokenSwapAddress(newAddress);
  const isValid = validateAddress(newAddress);
  setValidation(prevState => ({
    ...prevState,
    IsValidTokenSwap:isValid,
    validationMessage: isValid ? '' : 'Invalid address'
  }));
    
};
/**
 * Handle change in set destination user address input field.
 * @param {object} e - The event object from the input field.
 */
const handledestinationCurrencySwapChange = (e) => {

  const newAddress = e.target.value;
  setCurrencySwapAddress(newAddress);
  const isValid = validateAddress(newAddress);
  setValidation(prevState => ({
    ...prevState,
    IsValidCurrencySwap:isValid,
  }));
    
};
/**
 * Handle change in remove authentic user address input field.
 * @param {object} e - The event object from the input field.
 */
/**
 * Handles the change event when the user inputs a new address for authentic purposes.
 * Updates the component state with the new address, validates its format,
 * and sets the validity state accordingly.
 *
 * @param {Event} e - The change event object triggered by user input.
 * @returns {void}
 */
const handlesetmasterChange = (e) => {

  const newAddress = e.target.value;
  setMasteraddress(newAddress);
  const isValid = validateAddress(newAddress);
  setValidation(prevState => ({
    ...prevState,
    isValidMaster:isValid,
  }));
    
};

const updateData = async (e) => {
  e.preventDefault();
  setLoader(true);

  if (!address) {
    showNotification( "Please Connect with Wallet",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!apyamount) {
    showNotification( "Please enter a value Apyamount",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }


  updatedApy(addresses.contractAddress,apyamount,daysecond)
    .then(() => {
      showNotification("Transaction has been confirmed", NotificationTypes.SUCCESS);
      setApyPercentage('');
      LatesApyDeatil()
      
    })
    .catch((err) => {
      showNotification("Transaction has been rejected", NotificationTypes.ERROR);
      setLoader(false);
    }).finally(() => {
      setLoader(false);
    })
};
/**
 * Handles the submission of setting Stability Fee.
 * Performs validation, interacts with the contract to set Stability Fee,
 * and displays notifications based on transaction status.
 *
 * @async
 * @function SetStabilityFee
 * @param {Event} e - The submit event triggering the function.
 * @returns {void}
 */
const SetStabilityFee = async (e) => {
  e.preventDefault();
  setLoader(true);

  if (!address) {
    showNotification( "Please Connect with Wallet",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!StabilityFee) {
    showNotification( "Please enter a value StabilityFee",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }

  await  GetStabilityFee(addresses.contractAddress,StabilityFee)
    .then(() => {
      showNotification("Transaction has been confirmed", NotificationTypes.SUCCESS);
      SetStabilityFee('');
      GetStabilityPer()
      
    })
    .catch((err) => {
      showNotification("Transaction has been rejected", NotificationTypes.ERROR);
      setisLoader(false);
    }).finally(() => {
      setLoader(false);
    })
};

/**
 * Handles the submission of withdrawing collected fee.
 * Performs validation, interacts with the contract to withdraw fee,
 * and displays notifications based on transaction status.
 *
 * @async
 * @function withdrawCollectedFee
 * @param {Event} e - The submit event triggering the function.
 * @returns {void}
 */
const withdrawCollectedFee = async (e) => {
  e.preventDefault();
  setLoader(true);

  if (!address) {
    showNotification( "Please Connect with Wallet",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!withdrawFee) {
    showNotification( "Please enter a value Apyamount",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (parseFloat(withdrawFee) > parseFloat(balanceOf)) {
    showNotification("Entered amount exceeds available balance", NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  const withdrawFeeValue = parseFloat(withdrawFee);
  if (withdrawFeeValue === 0) {
    showNotification("Zero value is not allowed", NotificationTypes.ERROR);
    setLoader(false);
    return;
  }


  getwithdrawCollectedFee(addresses.contractAddress,withdrawFee,Masterwalletaddress)
    .then(() => {
      showNotification("Transaction has been confirmed", NotificationTypes.SUCCESS);
      setwithdrawFee('');
      GettotalCollected()

      
    })
    .catch((err) => {
      showNotification("Transaction has been rejected", NotificationTypes.ERROR);
      setLoader(false);
    }).finally(() => {
      setLoader(false);
    })
};
/**
 * Handles the submission of withdrawing collected fee.
 * Performs validation, interacts with the contract to withdraw fee,
 * and displays notifications based on transaction status.
 *
 * @async
 * @function withdrawCollectedFee
 * @param {Event} e - The submit event triggering the function.
 * @returns {void}
 */
const withdrawCollectedFeeETHJoin = async (e) => {
  e.preventDefault();
  setLoader(true);

  if (!address) {
    showNotification( "Please Connect with Wallet",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!validation.IsValiddestination) {
    showNotification( "invalid address",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!withdrawFeeETHJoin) {
    showNotification( "Please enter a value amount",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!destinationAddress) {
    showNotification( "Please enter a Destination Address",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (parseFloat(withdrawFeeETHJoin) > parseFloat(balanceOf)) {
    showNotification("Entered amount exceeds available balance", NotificationTypes.ERROR);
    setLoader(false);
    return;
  }


  getwithdrawCollectedFeeETHjoin(addresses.ETHJoin,withdrawFeeETHJoin,destinationAddress)
    .then(() => {
      showNotification("Transaction has been confirmed", NotificationTypes.SUCCESS);
      setwithdrawFeeETHJoin('');
      setdestinationAddress('')

      
    })
    .catch((err) => {
      showNotification("Transaction has been rejected", NotificationTypes.ERROR);
      setLoader(false);
    }).finally(() => {
      setLoader(false);
    })
};
/**
 * Handles the submission of withdrawing collected fee.
 * Performs validation, interacts with the contract to withdraw fee,
 * and displays notifications based on transaction status.
 *
 * @async
 * @function withdrawCollectedFeeSwap
 * @param {Event} e - The submit event triggering the function.
 * @returns {void}
 */
const withdrawCollectedFeeSwap = async (e) => {
  e.preventDefault();
  setLoader(true);

  if (!address) {
    showNotification( "Please Connect with Wallet",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!withdrawFeeSwap) {
    showNotification( "Please enter a value amount",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!destinationSwapAddress) {
    showNotification( "Please enter a Destination Address",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (parseFloat(withdrawFeeSwap) > parseFloat(balanceOf)) {
    showNotification("Entered amount exceeds available balance", NotificationTypes.ERROR);
    setLoader(false);
    return;
  }


  getwithdrawCollectedFeeSwap(addresses.Swap,withdrawFeeSwap,destinationSwapAddress)
    .then(() => {
      showNotification("Transaction has been confirmed", NotificationTypes.SUCCESS);
      setwithdrawFeeSwap('');
      setdestinationSwapAddress('')

      
    })
    .catch((err) => {
      showNotification("Transaction has been rejected", NotificationTypes.ERROR);
      setLoader(false);
    }).finally(() => {
      setLoader(false);
    })
};
/**
 * Handles the submission of withdrawing collected fee.
 * Performs validation, interacts with the contract to withdraw fee,
 * and displays notifications based on transaction status.
 *
 * @async
 * @function withdrawCollectedFee
 * @param {Event} e - The submit event triggering the function.
 * @returns {void}
 */
const withdrawCurrencyAmount = async (e) => {
  e.preventDefault();
  setLoader(true);

  if (!address) {
    showNotification( "Please Connect with Wallet",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!CurrencyAmount) {
    showNotification( "Please enter a value amount",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!validation.IsValidCurrency) {
    showNotification( "invalid address",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!CurrencyAddress) {
    showNotification( "Please enter a Destination Address",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (parseFloat(CurrencyAmount) > parseFloat(EmergencyCurrency)) {
    showNotification("Entered amount exceeds available balance", NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  const CurrencyAmountValue = parseFloat(CurrencyAmount);
  if (CurrencyAmountValue === 0) {
    showNotification("Zero value is not allowed", NotificationTypes.ERROR);
    setLoader(false);
    return;
  }

  getwithdrawCollectedCurrencyAmount(addresses.ETHJoin,CurrencyAmount,CurrencyAddress)
    .then(() => {
      showNotification("Transaction has been confirmed", NotificationTypes.SUCCESS);
      setCurrencyAmount('');
      setCurrencyAddress('')
      GetEmergencyCurrencyFee()

      
    })
    .catch((err) => {
      showNotification("Transaction has been rejected", NotificationTypes.ERROR);
      setLoader(false);
    }).finally(() => {
      setLoader(false);
    })
};
/**
 * Handles the submission of withdrawing collected fee.
 * Performs validation, interacts with the contract to withdraw fee,
 * and displays notifications based on transaction status.
 *
 * @async
 * @function withdrawTokenAmount
 * @param {Event} e - The submit event triggering the function.
 * @returns {void}
 */
const withdrawTokenAmount = async (e) => {
  e.preventDefault();
  setLoader(true);

  if (!address) {
    showNotification( "Please Connect with Wallet",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!TokenAmount) {
    showNotification( "Please enter a value amount",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!validation.IsValidToken) {
    showNotification( "invalid address",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!TokenAddress) {
    showNotification( "Please enter a Destination Address",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (parseFloat(TokenAmount) > parseFloat(TotalTokenBalance)) {
    showNotification("Entered amount exceeds available balance", NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  const TokenAmountValue = parseFloat(TokenAmount);
  if (TokenAmountValue === 0) {
    showNotification("Zero value is not allowed", NotificationTypes.ERROR);
    setLoader(false);
    return;
  }


  // getwithdrawCollectedTokenAmount(addresses.ETHJoin,TokenAmount,TokenAddress,addresses.ERUSDToken)
  getwithdrawCollectedTokenETHAmount(addresses.ETHJoin,TokenAmount,TokenAddress,addresses.ERUSDToken)
  
  .then(() => {
      showNotification("Transaction has been confirmed", NotificationTypes.SUCCESS);
      setTokenAmount('');
      setTokenAddress('')
      GetBalanceOFErusd()

      
    })
    .catch((err) => {
      showNotification("Transaction has been rejected", NotificationTypes.ERROR);
      setLoader(false);
    }).finally(() => {
      setLoader(false);
    })
};

/**
 * Handles the submission of withdrawing collected fee.
 * Performs validation, interacts with the contract to withdraw fee,
 * and displays notifications based on transaction status.
 *
 * @async
 * @function withdrawTokenSwapAmount
 * @param {Event} e - The submit event triggering the function.
 * @returns {void}
 */
const withdrawTokenSwapAmount = async (e) => {
  e.preventDefault();
  setLoader(true);

  if (!address) {
    showNotification( "Please Connect with Wallet",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!SwapTokenAmount) {
    showNotification( "Please enter a value amount",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!validation.IsValidTokenSwap) {
    showNotification( "invalid address",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!TokenSwapAddress) {
    showNotification( "Please enter a Destination Address",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (parseFloat(SwapTokenAmount) > parseFloat(TokenAmountSwap)) {
    showNotification("Entered amount exceeds available balance", NotificationTypes.ERROR);
    setLoader(false);
    return;
  }


  getwithdrawCollectedTokenSwapAmount(addresses.Swap,SwapTokenAmount,TokenSwapAddress,addresses.ERUSDToken)
    .then(() => {
      showNotification("Transaction has been confirmed", NotificationTypes.SUCCESS);
      setTokenSwapAmount('');
      setTokenSwapAddress('')

      
    })
    .catch((err) => {
      showNotification("Transaction has been rejected", NotificationTypes.ERROR);
      setLoader(false);
    }).finally(() => {
      setLoader(false);
    })
};
/**
 * Handles the submission of withdrawing collected fee.
 * Performs validation, interacts with the contract to withdraw fee,
 * and displays notifications based on transaction status.
 *
 * @async
 * @function withdrawCollectedFee
 * @param {Event} e - The submit event triggering the function.
 * @returns {void}
 */
const withdrawCurrencyAmountSwap = async (e) => {
  e.preventDefault();
  setLoader(true);

  if (!address) {
    showNotification( "Please Connect with Wallet",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!CurrencyAmountSwap) {
    showNotification( "Please enter a value amount",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!validation.IsValidCurrencySwap) {
    showNotification( "invalid address",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!SwapCurrencyAddress) {
    showNotification( "Please enter a Destination Address",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (parseFloat(CurrencyAmountSwap) > parseFloat(EmergencyCurrency)) {
    showNotification("Entered amount exceeds available balance", NotificationTypes.ERROR);
    setLoader(false);
    return;
  }


  getEmergencyWithdrawCurrencySwap(addresses.Swap,CurrencyAmountSwap,SwapCurrencyAddress)
    .then(() => {
      showNotification("Transaction has been confirmed", NotificationTypes.SUCCESS);
      setCurrencyAmountSwap('');
      setCurrencySwapAddress('')

      
    })
    .catch((err) => {
      showNotification("Transaction has been rejected", NotificationTypes.ERROR);
      setLoader(false);
    }).finally(() => {
      setLoader(false);
    })
};
/**
 * Handles the submission of Liquidation Penalty Amount.
 * Performs validation, interacts with the contract to withdraw fee,
 * and displays notifications based on transaction status.
 *
 * @async
 * @function LiquidationPenaltyAmount
 * @param {Event} e - The submit event triggering the function.
 * @returns {void}
 */

const LiquidationPenaltyAmount = async (e) => {
  e.preventDefault();
  setLoader(true);

  if (!address) {
    showNotification( "Please Connect with Wallet",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!LiquidationPenalty) {
    showNotification( "Please enter a value amount",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  


  getwithdrawLiquidationPenaltyAmount(LiquidationPenalty)
    .then(() => {
      showNotification("Transaction has been confirmed", NotificationTypes.SUCCESS);
      setLiquidationPenalty('');
      liquidationPenalty()


      
    })
    .catch((err) => {
      showNotification("Transaction has been rejected", NotificationTypes.ERROR);
      setLoader(false);
    }).finally(() => {
      setLoader(false);
    })
};
/**
 * Handles the submission of Liquidation Penalty Amount.
 * Performs validation, interacts with the contract to withdraw fee,
 * and displays notifications based on transaction status.
 *
 * @async
 * @function LiquidationPenaltyAmount
 * @param {Event} e - The submit event triggering the function.
 * @returns {void}
 */



const IncentiveFeeAmount = async (e) => {
  e.preventDefault();
  setLoader(true);

  if (!address) {
    showNotification( "Please Connect with Wallet",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!IncentiveFee) {
    showNotification( "Please enter a value amount",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  


  getIncentiveFeeAmount(IncentiveFee)
    .then(() => {
      showNotification("Transaction has been confirmed", NotificationTypes.SUCCESS);
      setIncentiveFee('');
      GetTotalIncentiveFee()

      
    })
    .catch((err) => {
      showNotification("Transaction has been rejected", NotificationTypes.ERROR);
      setLoader(false);
    }).finally(() => {
      setLoader(false);
    })
};

/**
 * Handles the submission of Liquidation Penalty Amount.
 * Performs validation, interacts with the contract to withdraw fee,
 * and displays notifications based on transaction status.
 *
 * @async
 * @function LiquidationPenaltyAmount
 * @param {Event} e - The submit event triggering the function.
 * @returns {void}
 */



const OracleRateAmount = async (e) => {
  e.preventDefault();
  setLoader(true);

  if (!address) {
    showNotification( "Please Connect with Wallet",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!OracleRate) {
    showNotification( "Please enter a value amount",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  const oracleRateValue = parseFloat(OracleRate);
  if (oracleRateValue === 0) {
    showNotification("Zero value is not allowed", NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  
  getOracleRate(OracleRate)
    .then(() => {
      showNotification("Transaction has been confirmed", NotificationTypes.SUCCESS);
      setOracleRate('');

      
    })
    .catch((err) => {
      showNotification("Transaction has been rejected", NotificationTypes.ERROR);
      setLoader(false);
    }).finally(() => {
      setLoader(false);
    })
};

/**
 * Sets an Master wallet in the contract.
 * Validates wallet connection and address, interacts with the contract to set the user,
 * and displays notifications based on transaction status.
 *
 * @async
 * @function SetMasterWallet
 * @param {Event} e - The submit event triggering the function.
 * @returns {void}
 */
const SetMasterWallet = async (e) => {
  e.preventDefault();
  setLoader(true);

  if (!address) {
    showNotification( "Please Connect with Wallet",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }
  if (!Masteraddress) {
    showNotification( "Please enter a  Address",NotificationTypes.ERROR);
    setLoader(false);
    return;
  }


  getMasterWallet(Masteraddress)
    .then(() => {
      showNotification("Transaction has been confirmed", NotificationTypes.SUCCESS);
      setMasteraddress('');
      
    })
    .catch((err) => {
      showNotification("Transaction has been rejected", NotificationTypes.ERROR);
      setLoader(false);
    }).finally(() => {
      setLoader(false);
    })
};
const setFeeText = EmergencyCurrency ? `Current Amount ${EmergencyCurrency.toFixed(10)} ETC` : '';
const Collectedfee = balanceOf ? `Current fee ${balanceOf.toFixed(10)} ETC` : '';
const tabs = [
  {
    eventKey: "Oracle",
    title: "ETC Rate",
    forms: [
      {
        label: "Set Eth Rate", // First form in the new tab
        controlId: "SetAuthenticUser",
        placeholder: "Enter Eth rate",
        validationMessage:"Invalid address",
        showSubmit: true,
        value: OracleRate,
        onChange: handleOracleRateChange,
        onSubmit: OracleRateAmount,
        buttonLoading: Loder,
        isValid:validation.isValiddata,
        infoText:"Change ETC rate from here (FOR TESTING PURPOSE ONLY)        ",



      },
      
    ],
    
    },
  {
    eventKey: "apy",
    title: "APY",
    forms: [
      {
        label: "APY Percentage",
        controlId: "formApyPercentage",
        value: apyamount,
        onChange: handleApyPercentageChange,
        placeholder: "Enter APY Percentage",
        onSubmit: updateData,
        buttonLoading: Loder,
        isValid:validation.isValiddata,
        showSubmit: true,
        validationMessage:"Please enter APY Percentage",
        infoText:"Change APY percentage from here. (5% by default)",
        setFee: `Current APY  ${SellerusdFee}%` // Add



      },
      {
        label: "Stability Fee",
        controlId: "StabilityFee",
        value: StabilityFee,
        onChange: handleStabilityFeeChange,
        placeholder: "Enter Stability Fee",
        onSubmit: SetStabilityFee,
        buttonLoading: Loder,
        isValid:validation.isValiddata,
        showSubmit: true,
        infoText:"Change ETC deposit fee from here. (4% by default)        ",
        setFee: `Current Stability Fee ${TotalStabiltyFee}%` // Add



      },
      {
        label: "Withdraw Collected Fee",
        controlId: "withdrawCollectedFee",
        value: withdrawFee,
        onChange: handlewithdrawCollectedFeeChange,
        placeholder: "Enter Withdraw Collected Fee",
        onSubmit: withdrawCollectedFee,
        buttonLoading: Loder,
        isValid:validation.validationBalanceApy ==='',
        showSubmit: true,
        validationMessage:validation.validationBalanceApy,
        infoText:"Withdraw collateral fee from here in master wallet        ",
        setFee: Collectedfee // Add




      },
    ],
  },
 
  
  {
    eventKey: "ETHJoin", // New eventKey for the new tab
    title: "ETH Join", // Title of the new tab
    forms: [
      
      // {
      //   label: "Withdraw Fee", // Third form in the new tab
      //   controlId: "WithdrawFeeETHJoin",
      //   value: destinationAddress,
      //   onChange: handledestinationChange,
      //   placeholder: "Enter Destination Address",
      //   buttonLoading: isLoder,
      //   showSubmit: false,
      //   isValid:validation.IsValiddestination,
      //   validationMessage:"Invalid address",
      //   onSubmit: withdrawCollectedFeeETHJoin,
      //   infoText:"Withdraw fee from here.        ",




      // },
      // {
      //   controlId: "WithdrawFeeETHJoin",
      //   placeholder: "Enter a amount Address",
      //   value: withdrawFeeETHJoin,
      //   onChange: handlewithdrawETHJoinChange,
      //   placeholder: "Enter Withdraw Collected Fee",
      //   onSubmit: withdrawCollectedFeeETHJoin,
      //   buttonLoading: Loder,
      //   isValid:validation.validationBalanceWithdraw==='',
      //   showSubmit: true,
      //   validationMessage:validation.validationBalanceWithdraw,
      //   setFee: `Current Withdraw ${balanceOf} ETC` // Add

      // },
       
      
      {
        label: "Emergency Withdraw Token",
        controlId: "EmergencyWithdrawTokenAmountETHJoin",
        value: TokenAddress,
        placeholder: "Enter beneficiary address",
        onChange: handledestinationTokenChange,
        buttonLoading: isLoder,
        showSubmit: false,
        isValid:validation.IsValidToken,
        validationMessage:"Invalid address",
        onSubmit: withdrawTokenAmount,
        infoText:"Withdraw tokens from here (FOR TESTING PURPOSE ONLY).",




        
      },
      {
        controlId: "EmergencyWithdrawTokenAmountETHJoin",
        placeholder: "Enter a Token amount ",
        value: TokenAmount,
        onChange: handleTokenAmountChange,
        onSubmit: withdrawTokenAmount,
        buttonLoading: Loder,
        isValid:validation.validationBalance==='',
        showSubmit: true,
        validationMessage:validation.validationBalance,
        setFee: `Current Token ${TotalTokenBalance} ERUSD` // Add


        
      },
      {
        label: "Emergency Withdraw Currency ",
        controlId: "EmergencyWithdrawCurrencyAmountETHJoin",
        value: CurrencyAddress,
        onChange: handledestinationCurrencyChange,
        placeholder: "Enter Destination Address",
        buttonLoading: isLoder,
        showSubmit: false,
        isValid:validation.IsValidCurrency,
        validationMessage:"Invalid address",
        onSubmit: withdrawCurrencyAmount,
        infoText:"Withdraw currency from here (FOR TESTING PURPOSE ONLY)        .",


        

      },
      {
        controlId: "EmergencyWithdrawCurrencyAmountETHJoin",
        placeholder: "Enter Currency Amount",
        value: CurrencyAmount,
        onChange: handleCurrencyAmountChange,
        onSubmit: withdrawCurrencyAmount,
        buttonLoading: Loder,
        isValid:validation.validationEmergencyCurrency==='',
        showSubmit: true,
        validationMessage:validation.validationEmergencyCurrency,
        setFee: setFeeText // Add




      },
     
    ],
  },
 
  {
    eventKey: "liquidation",
    title: "Liquidation",
    forms: [
      {
        label: "Set MasterWallet",
        controlId: "SetMasterWalletLiquidation",
        value: Masteraddress,
        onChange: handlesetmasterChange,
        placeholder: "Enter MasterWallet address",
        isValid:validation.isValidMaster,
        onSubmit: SetMasterWallet,
        buttonLoading: Loder,
        showSubmit: true,
        validationMessage:"Invalid address",
        infoText:"Change master wallet address from here.",



      },
      {
        label: "Set Liquidation Penalty",
        controlId: "SetLiquidationPenaltyLiquidation",
        placeholder: "Enter Liquidation Penalty",
        showSubmit: true,
        value: LiquidationPenalty,
        onChange: handleLiquidationPenaltyChange,
        onSubmit: LiquidationPenaltyAmount,
        buttonLoading: Loder,
        isValid:validation.isValiddata,
        infoText:"Set % change to cause liquidation (13% by default).        ",
        setFee: `Current Penalty ${liquidationPenaltyfee}%` // Add

      },
      {
        label: "Set Incentive Fee",
        controlId: "SetIncentiveFeeLiquidation",
        placeholder: "Enter Incentive Fee",
        showSubmit: true,
        value: IncentiveFee,
        onChange: handleIncentiveFeeChange,
        onSubmit: IncentiveFeeAmount,
        buttonLoading: Loder,
        isValid:validation.isValiddata,
        infoText:"Change fee deducted when liquidation is triggered.",
        setFee: `Current Incentive Fee ${TotalIncentiveFee}%` // Add


      },
    ],
  },
  
      // {
      //   eventKey: "Swap",
      //   title: "Swap",
      //   forms: [
         
      //     {
      //       label: "Emergency Withdraw Currency Amount",
      //       controlId: "EmergencyWithdrawCurrencyAmountETHJoin",
      //       value: SwapCurrencyAddress,
      //       onChange: handledestinationCurrencySwapChange,
      //       placeholder: "Enter Destination Address",
      //       buttonLoading: isLoder,
      //       showSubmit: false,
      //       isValid:validation.IsValidCurrencySwap,
      //       validationMessage:"Invalid address",
      //       onSubmit: withdrawCurrencyAmountSwap,
      //       infoText:"Withdraw currency from here (FOR TESTING PURPOSE ONLY).            .",
            



      //     },
      //     {
      //       controlId: "EmergencyWithdrawCurrencyAmountSwap",
      //       placeholder: "Enter Currency Amount",
      //       value: CurrencyAmountSwap,
      //       onChange: handleCurrencyAmountSwapChange,
      //       onSubmit: withdrawCurrencyAmountSwap,
      //       buttonLoading: Loder,
      //       isValid:validation.validationSwap==='',
      //       showSubmit: true,
      //       validationMessage:validation.validationSwap,
      //       setFee: `EmergencyWithdrawCurrency ${EmergencyCurrency}ETC` // Add


            
    
      //     },
      //     {
      //       label: "Emergency Withdraw Token",
      //       controlId: "EmergencyWithdrawTokenAmountETHJoin",
      //       value: TokenSwapAddress,
      //       placeholder: "Enter beneficiary address",
      //       onChange: handledestinationTokenSwapChange,
      //       buttonLoading: isLoder,
      //       showSubmit: false,
      //       isValid:validation.IsValidTokenSwap,
      //       validationMessage:"Invalid address",
      //       infoText:"Withdraw tokens from here (FOR TESTING PURPOSE ONLY)."            ,


    
            
      //     },
      //     {
      //       controlId: "EmergencyWithdrawTokenAmountETHJoin",
      //       placeholder: "Enter a Token amount ",
      //       value: SwapTokenAmount,
      //       onChange: handleTokenSwapAmountChange,
      //       onSubmit: withdrawTokenSwapAmount,
      //       buttonLoading: Loder,
      //       isValid:validation.validationTokenSwap==='',
      //       showSubmit: true,
      //       validationMessage:validation.validationTokenSwap,
      //       setFee: `EmergencyWithdrawToken ${TokenAmountSwap} ERUSD` // Add


    
            
      //     },
      //   ],
      // },
     
];

  return (
    <>
      <header>
        <Container>
          <Row>
          {(Loder)&& (
        <div className="full-screen-loader">
                      <img src={laoder} alt="" className="loader-spinner"  />
        </div>
      )}
              <Col xl="12" lg="12" className="text-center"  id="frequently">
              {!isAdmin  && <h3 className="welcome">WELCOME TO ADMIN PANEL</h3>}
             
                  {isAdmin  ? (
                    <>
                          <TabsComponent tabs={tabs} />                
                    </>
                  ) : (
                    <p>You are not authorized to view this page.</p>

                  )}
            </Col>
          </Row>
        </Container>
      </header>
         </>
  );
}
export default Home;

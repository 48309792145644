import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import { Container, Form, Nav, Navbar, NavDropdown } from "react-bootstrap";
import Logo from "../Assets/Images/MakerDao.svg";
import cross from "../Assets/Images/Vector.png";
import bars from "../Assets/Images/Group 1029.png";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import Toast, { NotificationTypes, showNotification } from "../utils/Toast/toast";
import {
  ChainId,
  ConnectWallet,
  useAddress,
  useChainId,
  useDisconnect,
  useNetwork,
  useNetworkMismatch,
} from "@thirdweb-dev/react";
import {
  
  ChainIds,
  
} from "../Const/const";


function NavBar({ ethAmount }) {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false); // State to track Navbar.Toggle toggle
  const [copied, setCopied] = useState(false);
  const address = useAddress();
  const disconnect = useDisconnect();
  const chainId = useChainId();
  const isWrongBlockcahin = useNetworkMismatch();
  const [, switchNetwork] = useNetwork();
  const [collapseOpen, setCollapseOpen] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  useEffect(() => {
    if (isWrongBlockcahin) {
      switchNetwork(ChainIds);
    }
  }, [address, isWrongBlockcahin, switchNetwork]);
  

  const isWrongUrl = useLocation.pathname !== "/"; // Adjust this condition based on your URL structure

  const handleNavLinkClick = () => {
    setIsNavbarOpen(false); // Close the mobile menu when a Nav.Link is clicked
  };

  const handleNavItemClick = () => {
    setCollapseOpen(false);
  };

  useEffect(() => {
    setCollapseOpen(false);
  }, []);
  const handleCopyClick = () => {
    const addressText = address;

    if (addressText) {
      navigator.clipboard.writeText(addressText);
      setCopied(true);
      showNotification("Address Copied",NotificationTypes.SUCCESS);

      // Reset copied state after a short delay
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  };
  return (
    <>
      <Navbar
        expand="lg"
        className={`${collapseOpen ? "navbar-open" : ""}`}
        id="navbar"
      >
        <Container>
          <Navbar.Brand href="">
            <div>
              {isWrongUrl ? (
                <Link to="/">
                  <img src={Logo} alt="Logo" />
                </Link>
              ) : (
                <img src={Logo} alt="Logo" />
              )}
              
            </div>
          </Navbar.Brand>
          {/* <Navbar.Toggle id="navbarScroll" aria-controls="navbarScroll" /> */}
          <div
                className="collaspbtn"
                onClick={() => {
                  setCollapseOpen(!collapseOpen);
                }}
              >
                {collapseOpen ? (
                  <img src={cross} className="cross" />
                ) : (
                  <img src={bars} className="Bars" />
                )}
              </div>
          <Navbar.Collapse in={collapseOpen}>
            <Nav className="me-auto my-2 my-lg-0">
              
            </Nav>
            <div className="d-flex mobile-btn-cntnr">
              {address && chainId == ChainIds ? (
                  <>
                    <Button className='reg-btn white' onClick={handleCopyClick}>
                      {address?.slice(0, 5) + "..." + address?.slice(-4)}
                    </Button>
                    <Button className="reg-btn dark" onClick={disconnect}>
                      Disconnect
                    </Button>
                  </>
                ) : (
                  <ConnectWallet
                  welcomeScreen={{
                    title: "Maker Dao",
                  }}
                    className="reg-btn white"
                    btnTitle={"Connect"}
                    switchToActiveChain={true}
                  />
                )}
            
            </div>
            <div className="line"></div>
            <div className="etc-amount">
              <p> ETC :</p>
              <p className="etc-balance">${ethAmount ? ethAmount : "0"}</p>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
export default NavBar;
